@charset "UTF-8";
.mat-badge-content{font-weight:600;font-size:12px;font-family:Roboto, "Helvetica Neue", sans-serif}
.mat-badge-small .mat-badge-content{font-size:9px}
.mat-badge-large .mat-badge-content{font-size:24px}
.mat-h1,.mat-headline,.mat-typography h1{font:400 24px/32px Roboto, "Helvetica Neue", sans-serif;letter-spacing:normal;margin:0 0 16px}
.mat-h2,.mat-title,.mat-typography h2{font:500 20px/32px Roboto, "Helvetica Neue", sans-serif;letter-spacing:normal;margin:0 0 16px}
.mat-h3,.mat-subheading-2,.mat-typography h3{font:400 16px/28px Roboto, "Helvetica Neue", sans-serif;letter-spacing:normal;margin:0 0 16px}
.mat-h4,.mat-subheading-1,.mat-typography h4{font:400 15px/24px Roboto, "Helvetica Neue", sans-serif;letter-spacing:normal;margin:0 0 16px}
.mat-h5,.mat-typography h5{font:400 calc(14px * 0.83)/20px Roboto, "Helvetica Neue", sans-serif;margin:0 0 12px}
.mat-h6,.mat-typography h6{font:400 calc(14px * 0.67)/20px Roboto, "Helvetica Neue", sans-serif;margin:0 0 12px}
.mat-body-strong,.mat-body-2{font:500 14px/24px Roboto, "Helvetica Neue", sans-serif;letter-spacing:normal}
.mat-body,.mat-body-1,.mat-typography{font:400 14px/20px Roboto, "Helvetica Neue", sans-serif;letter-spacing:normal}
.mat-body p,.mat-body-1 p,.mat-typography p{margin:0 0 12px}
.mat-small,.mat-caption{font:400 12px/20px Roboto, "Helvetica Neue", sans-serif;letter-spacing:normal}
.mat-display-4,.mat-typography .mat-display-4{font:300 112px/112px Roboto, "Helvetica Neue", sans-serif;letter-spacing:-0.05em;margin:0 0 56px}
.mat-display-3,.mat-typography .mat-display-3{font:400 56px/56px Roboto, "Helvetica Neue", sans-serif;letter-spacing:-0.02em;margin:0 0 64px}
.mat-display-2,.mat-typography .mat-display-2{font:400 45px/48px Roboto, "Helvetica Neue", sans-serif;letter-spacing:-0.005em;margin:0 0 64px}
.mat-display-1,.mat-typography .mat-display-1{font:400 34px/40px Roboto, "Helvetica Neue", sans-serif;letter-spacing:normal;margin:0 0 64px}
.mat-bottom-sheet-container{font:400 14px/20px Roboto, "Helvetica Neue", sans-serif;letter-spacing:normal}
.mat-button,.mat-raised-button,.mat-icon-button,.mat-stroked-button,.mat-flat-button,.mat-fab,.mat-mini-fab{font-family:Roboto, "Helvetica Neue", sans-serif;font-size:14px;font-weight:500}
.mat-button-toggle{font-family:Roboto, "Helvetica Neue", sans-serif}
.mat-card{font-family:Roboto, "Helvetica Neue", sans-serif}
.mat-card-title{font-size:24px;font-weight:500}
.mat-card-header .mat-card-title{font-size:20px}
.mat-card-subtitle,.mat-card-content{font-size:14px}
.mat-checkbox{font-family:Roboto, "Helvetica Neue", sans-serif}
.mat-checkbox-layout .mat-checkbox-label{line-height:24px}
.mat-chip{font-size:14px;font-weight:500}
.mat-chip .mat-chip-trailing-icon.mat-icon,.mat-chip .mat-chip-remove.mat-icon{font-size:18px}
.mat-table{font-family:Roboto, "Helvetica Neue", sans-serif}
.mat-header-cell{font-size:12px;font-weight:500}
.mat-cell,.mat-footer-cell{font-size:14px}
.mat-calendar{font-family:Roboto, "Helvetica Neue", sans-serif}
.mat-calendar-body{font-size:13px}
.mat-calendar-body-label,.mat-calendar-period-button{font-size:14px;font-weight:500}
.mat-calendar-table-header th{font-size:11px;font-weight:400}
.mat-dialog-title{font:500 20px/32px Roboto, "Helvetica Neue", sans-serif;letter-spacing:normal}
.mat-expansion-panel-header{font-family:Roboto, "Helvetica Neue", sans-serif;font-size:15px;font-weight:400}
.mat-expansion-panel-content{font:400 14px/20px Roboto, "Helvetica Neue", sans-serif;letter-spacing:normal}
.mat-form-field{font-size:inherit;font-weight:400;line-height:1.125;font-family:Roboto, "Helvetica Neue", sans-serif;letter-spacing:normal}
.mat-form-field-wrapper{padding-bottom:1.34375em}
.mat-form-field-prefix .mat-icon,.mat-form-field-suffix .mat-icon{font-size:150%;line-height:1.125}
.mat-form-field-prefix .mat-icon-button,.mat-form-field-suffix .mat-icon-button{height:1.5em;width:1.5em}
.mat-form-field-prefix .mat-icon-button .mat-icon,.mat-form-field-suffix .mat-icon-button .mat-icon{height:1.125em;line-height:1.125}
.mat-form-field-infix{padding:.5em 0;border-top:.84375em solid transparent}
.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label{transform:translateY(-1.34375em) scale(0.75);width:133.3333333333%}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label{transform:translateY(-1.34374em) scale(0.75);width:133.3333433333%}
.mat-form-field-label-wrapper{top:-0.84375em;padding-top:.84375em}
.mat-form-field-label{top:1.34375em}
.mat-form-field-underline{bottom:1.34375em}
.mat-form-field-subscript-wrapper{font-size:75%;margin-top:.6666666667em;top:calc(100% - 1.7916666667em)}
.mat-form-field-appearance-legacy .mat-form-field-wrapper{padding-bottom:1.25em}
.mat-form-field-appearance-legacy .mat-form-field-infix{padding:.4375em 0}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label{transform:translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);-ms-transform:translateY(-1.28125em) scale(0.75);width:133.3333333333%}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill+.mat-form-field-label-wrapper .mat-form-field-label{transform:translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);-ms-transform:translateY(-1.28124em) scale(0.75);width:133.3333433333%}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label{transform:translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);-ms-transform:translateY(-1.28123em) scale(0.75);width:133.3333533333%}
.mat-form-field-appearance-legacy .mat-form-field-label{top:1.28125em}
.mat-form-field-appearance-legacy .mat-form-field-underline{bottom:1.25em}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper{margin-top:.5416666667em;top:calc(100% - 1.6666666667em)}
@media print{.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label{transform:translateY(-1.28122em) scale(0.75)}.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill+.mat-form-field-label-wrapper .mat-form-field-label{transform:translateY(-1.28121em) scale(0.75)}.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label{transform:translateY(-1.2812em) scale(0.75)}}
.mat-form-field-appearance-fill .mat-form-field-infix{padding:.25em 0 .75em 0}
.mat-form-field-appearance-fill .mat-form-field-label{top:1.09375em;margin-top:-0.5em}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label{transform:translateY(-0.59375em) scale(0.75);width:133.3333333333%}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label{transform:translateY(-0.59374em) scale(0.75);width:133.3333433333%}
.mat-form-field-appearance-outline .mat-form-field-infix{padding:1em 0 1em 0}
.mat-form-field-appearance-outline .mat-form-field-label{top:1.84375em;margin-top:-0.25em}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label{transform:translateY(-1.59375em) scale(0.75);width:133.3333333333%}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown)+.mat-form-field-label-wrapper .mat-form-field-label{transform:translateY(-1.59374em) scale(0.75);width:133.3333433333%}
.mat-grid-tile-header,.mat-grid-tile-footer{font-size:14px}
.mat-grid-tile-header .mat-line,.mat-grid-tile-footer .mat-line{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:block;box-sizing:border-box}
.mat-grid-tile-header .mat-line:nth-child(n+2),.mat-grid-tile-footer .mat-line:nth-child(n+2){font-size:12px}
input.mat-input-element{margin-top:-0.0625em}
.mat-menu-item{font-family:Roboto, "Helvetica Neue", sans-serif;font-size:14px;font-weight:400}
.mat-paginator,.mat-paginator-page-size .mat-select-trigger{font-family:Roboto, "Helvetica Neue", sans-serif;font-size:12px}
.mat-radio-button{font-family:Roboto, "Helvetica Neue", sans-serif}
.mat-select{font-family:Roboto, "Helvetica Neue", sans-serif}
.mat-select-trigger{height:1.125em}
.mat-slide-toggle-content{font-family:Roboto, "Helvetica Neue", sans-serif}
.mat-slider-thumb-label-text{font-family:Roboto, "Helvetica Neue", sans-serif;font-size:12px;font-weight:500}
.mat-stepper-vertical,.mat-stepper-horizontal{font-family:Roboto, "Helvetica Neue", sans-serif}
.mat-step-label{font-size:14px;font-weight:400}
.mat-step-sub-label-error{font-weight:normal}
.mat-step-label-error{font-size:14px}
.mat-step-label-selected{font-size:14px;font-weight:500}
.mat-tab-group{font-family:Roboto, "Helvetica Neue", sans-serif}
.mat-tab-label,.mat-tab-link{font-family:Roboto, "Helvetica Neue", sans-serif;font-size:14px;font-weight:500}
.mat-toolbar,.mat-toolbar h1,.mat-toolbar h2,.mat-toolbar h3,.mat-toolbar h4,.mat-toolbar h5,.mat-toolbar h6{font:500 20px/32px Roboto, "Helvetica Neue", sans-serif;letter-spacing:normal;margin:0}
.mat-tooltip{font-family:Roboto, "Helvetica Neue", sans-serif;font-size:10px;padding-top:6px;padding-bottom:6px}
.mat-tooltip-handset{font-size:14px;padding-top:8px;padding-bottom:8px}
.mat-list-item{font-family:Roboto, "Helvetica Neue", sans-serif}
.mat-list-option{font-family:Roboto, "Helvetica Neue", sans-serif}
.mat-list-base .mat-list-item{font-size:16px}
.mat-list-base .mat-list-item .mat-line{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:block;box-sizing:border-box}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2){font-size:14px}
.mat-list-base .mat-list-option{font-size:16px}
.mat-list-base .mat-list-option .mat-line{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:block;box-sizing:border-box}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2){font-size:14px}
.mat-list-base .mat-subheader{font-family:Roboto, "Helvetica Neue", sans-serif;font-size:14px;font-weight:500}
.mat-list-base[dense] .mat-list-item{font-size:12px}
.mat-list-base[dense] .mat-list-item .mat-line{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:block;box-sizing:border-box}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2){font-size:12px}
.mat-list-base[dense] .mat-list-option{font-size:12px}
.mat-list-base[dense] .mat-list-option .mat-line{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;display:block;box-sizing:border-box}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2){font-size:12px}
.mat-list-base[dense] .mat-subheader{font-family:Roboto, "Helvetica Neue", sans-serif;font-size:12px;font-weight:500}
.mat-option{font-family:Roboto, "Helvetica Neue", sans-serif;font-size:16px}
.mat-optgroup-label{font:500 14px/24px Roboto, "Helvetica Neue", sans-serif;letter-spacing:normal}
.mat-simple-snackbar{font-family:Roboto, "Helvetica Neue", sans-serif;font-size:14px}
.mat-simple-snackbar-action{line-height:1;font-family:inherit;font-size:inherit;font-weight:500}
.mat-tree{font-family:Roboto, "Helvetica Neue", sans-serif}
.mat-tree-node,.mat-nested-tree-node{font-weight:400;font-size:14px}
.mat-ripple{overflow:hidden;position:relative}
.mat-ripple:not(:empty){transform:translateZ(0)}
.mat-ripple.mat-ripple-unbounded{overflow:visible}
.mat-ripple-element{position:absolute;border-radius:50%;pointer-events:none;transition:opacity,transform 0ms cubic-bezier(0, 0, 0.2, 1);transform:scale(0)}
.cdk-high-contrast-active .mat-ripple-element{display:none}
.cdk-visually-hidden{border:0;clip:rect(0 0 0 0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px;outline:0;-webkit-appearance:none;-moz-appearance:none}
.cdk-overlay-container,.cdk-global-overlay-wrapper{pointer-events:none;top:0;left:0;height:100%;width:100%}
.cdk-overlay-container{position:fixed;z-index:1000}
.cdk-overlay-container:empty{display:none}
.cdk-global-overlay-wrapper{display:flex;position:absolute;z-index:1000}
.cdk-overlay-pane{position:absolute;pointer-events:auto;box-sizing:border-box;z-index:1000;display:flex;max-width:100%;max-height:100%}
.cdk-overlay-backdrop{position:absolute;top:0;bottom:0;left:0;right:0;z-index:1000;pointer-events:auto;-webkit-tap-highlight-color:transparent;transition:opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);opacity:0}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing{opacity:1}
@media screen and (-ms-high-contrast: active){.cdk-overlay-backdrop.cdk-overlay-backdrop-showing{opacity:.6}}
.cdk-overlay-dark-backdrop{background:rgba(0,0,0,.32)}
.cdk-overlay-transparent-backdrop,.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing{opacity:0}
.cdk-overlay-connected-position-bounding-box{position:absolute;z-index:1000;display:flex;flex-direction:column;min-width:1px;min-height:1px}
.cdk-global-scrollblock{position:fixed;width:100%;overflow-y:scroll}
@keyframes cdk-text-field-autofill-start{/*!*/}
@keyframes cdk-text-field-autofill-end{/*!*/}
.cdk-text-field-autofill-monitored:-webkit-autofill{animation:cdk-text-field-autofill-start 0s 1ms}
.cdk-text-field-autofill-monitored:not(:-webkit-autofill){animation:cdk-text-field-autofill-end 0s 1ms}
textarea.cdk-textarea-autosize{resize:none}
textarea.cdk-textarea-autosize-measuring{padding:2px 0 !important;box-sizing:content-box !important;height:auto !important;overflow:hidden !important}
textarea.cdk-textarea-autosize-measuring-firefox{padding:2px 0 !important;box-sizing:content-box !important;height:0 !important}
.mat-focus-indicator{position:relative}
.mat-mdc-focus-indicator{position:relative}
.mat-ripple-element{background-color:rgba(0,0,0,.1)}
.mat-option{color:rgba(0,0,0,.87)}
.mat-option:hover:not(.mat-option-disabled),.mat-option:focus:not(.mat-option-disabled){background:rgba(0,0,0,.04)}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled){background:rgba(0,0,0,.04)}
.mat-option.mat-active{background:rgba(0,0,0,.04);color:rgba(0,0,0,.87)}
.mat-option.mat-option-disabled{color:rgba(0,0,0,.38)}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled){color:#3f51b5}
.mat-accent .mat-option.mat-selected:not(.mat-option-disabled){color:#ff4081}
.mat-warn .mat-option.mat-selected:not(.mat-option-disabled){color:#f44336}
.mat-optgroup-label{color:rgba(0,0,0,.54)}
.mat-optgroup-disabled .mat-optgroup-label{color:rgba(0,0,0,.38)}
.mat-pseudo-checkbox{color:rgba(0,0,0,.54)}
.mat-pseudo-checkbox::after{color:#fafafa}
.mat-pseudo-checkbox-disabled{color:#b0b0b0}
.mat-primary .mat-pseudo-checkbox-checked,.mat-primary .mat-pseudo-checkbox-indeterminate{background:#3f51b5}
.mat-pseudo-checkbox-checked,.mat-pseudo-checkbox-indeterminate,.mat-accent .mat-pseudo-checkbox-checked,.mat-accent .mat-pseudo-checkbox-indeterminate{background:#ff4081}
.mat-warn .mat-pseudo-checkbox-checked,.mat-warn .mat-pseudo-checkbox-indeterminate{background:#f44336}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled{background:#b0b0b0}
.mat-app-background{background-color:#fafafa;color:rgba(0,0,0,.87)}
.mat-elevation-z0{box-shadow:0px 0px 0px 0px rgba(0, 0, 0, 0.2),0px 0px 0px 0px rgba(0, 0, 0, 0.14),0px 0px 0px 0px rgba(0, 0, 0, 0.12)}
.mat-elevation-z1{box-shadow:0px 2px 1px -1px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 1px 3px 0px rgba(0, 0, 0, 0.12)}
.mat-elevation-z2{box-shadow:0px 3px 1px -2px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 1px 5px 0px rgba(0, 0, 0, 0.12)}
.mat-elevation-z3{box-shadow:0px 3px 3px -2px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 1px 8px 0px rgba(0, 0, 0, 0.12)}
.mat-elevation-z4{box-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)}
.mat-elevation-z5{box-shadow:0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 5px 8px 0px rgba(0, 0, 0, 0.14),0px 1px 14px 0px rgba(0, 0, 0, 0.12)}
.mat-elevation-z6{box-shadow:0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)}
.mat-elevation-z7{box-shadow:0px 4px 5px -2px rgba(0, 0, 0, 0.2),0px 7px 10px 1px rgba(0, 0, 0, 0.14),0px 2px 16px 1px rgba(0, 0, 0, 0.12)}
.mat-elevation-z8{box-shadow:0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12)}
.mat-elevation-z9{box-shadow:0px 5px 6px -3px rgba(0, 0, 0, 0.2),0px 9px 12px 1px rgba(0, 0, 0, 0.14),0px 3px 16px 2px rgba(0, 0, 0, 0.12)}
.mat-elevation-z10{box-shadow:0px 6px 6px -3px rgba(0, 0, 0, 0.2),0px 10px 14px 1px rgba(0, 0, 0, 0.14),0px 4px 18px 3px rgba(0, 0, 0, 0.12)}
.mat-elevation-z11{box-shadow:0px 6px 7px -4px rgba(0, 0, 0, 0.2),0px 11px 15px 1px rgba(0, 0, 0, 0.14),0px 4px 20px 3px rgba(0, 0, 0, 0.12)}
.mat-elevation-z12{box-shadow:0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 12px 17px 2px rgba(0, 0, 0, 0.14),0px 5px 22px 4px rgba(0, 0, 0, 0.12)}
.mat-elevation-z13{box-shadow:0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 13px 19px 2px rgba(0, 0, 0, 0.14),0px 5px 24px 4px rgba(0, 0, 0, 0.12)}
.mat-elevation-z14{box-shadow:0px 7px 9px -4px rgba(0, 0, 0, 0.2),0px 14px 21px 2px rgba(0, 0, 0, 0.14),0px 5px 26px 4px rgba(0, 0, 0, 0.12)}
.mat-elevation-z15{box-shadow:0px 8px 9px -5px rgba(0, 0, 0, 0.2),0px 15px 22px 2px rgba(0, 0, 0, 0.14),0px 6px 28px 5px rgba(0, 0, 0, 0.12)}
.mat-elevation-z16{box-shadow:0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12)}
.mat-elevation-z17{box-shadow:0px 8px 11px -5px rgba(0, 0, 0, 0.2),0px 17px 26px 2px rgba(0, 0, 0, 0.14),0px 6px 32px 5px rgba(0, 0, 0, 0.12)}
.mat-elevation-z18{box-shadow:0px 9px 11px -5px rgba(0, 0, 0, 0.2),0px 18px 28px 2px rgba(0, 0, 0, 0.14),0px 7px 34px 6px rgba(0, 0, 0, 0.12)}
.mat-elevation-z19{box-shadow:0px 9px 12px -6px rgba(0, 0, 0, 0.2),0px 19px 29px 2px rgba(0, 0, 0, 0.14),0px 7px 36px 6px rgba(0, 0, 0, 0.12)}
.mat-elevation-z20{box-shadow:0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 20px 31px 3px rgba(0, 0, 0, 0.14),0px 8px 38px 7px rgba(0, 0, 0, 0.12)}
.mat-elevation-z21{box-shadow:0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 21px 33px 3px rgba(0, 0, 0, 0.14),0px 8px 40px 7px rgba(0, 0, 0, 0.12)}
.mat-elevation-z22{box-shadow:0px 10px 14px -6px rgba(0, 0, 0, 0.2),0px 22px 35px 3px rgba(0, 0, 0, 0.14),0px 8px 42px 7px rgba(0, 0, 0, 0.12)}
.mat-elevation-z23{box-shadow:0px 11px 14px -7px rgba(0, 0, 0, 0.2),0px 23px 36px 3px rgba(0, 0, 0, 0.14),0px 9px 44px 8px rgba(0, 0, 0, 0.12)}
.mat-elevation-z24{box-shadow:0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)}
.mat-theme-loaded-marker{display:none}
.mat-autocomplete-panel{background:#fff;color:rgba(0,0,0,.87)}
.mat-autocomplete-panel:not([class*=mat-elevation-z]){box-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover){background:#fff}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled){color:rgba(0,0,0,.87)}
.mat-badge-content{color:#fff;background:#3f51b5}
.cdk-high-contrast-active .mat-badge-content{outline:solid 1px;border-radius:0}
.mat-badge-accent .mat-badge-content{background:#ff4081;color:#fff}
.mat-badge-warn .mat-badge-content{color:#fff;background:#f44336}
.mat-badge{position:relative}
.mat-badge-hidden .mat-badge-content{display:none}
.mat-badge-disabled .mat-badge-content{background:#b9b9b9;color:rgba(0,0,0,.38)}
.mat-badge-content{position:absolute;text-align:center;display:inline-block;border-radius:50%;transition:transform 200ms ease-in-out;transform:scale(0.6);overflow:hidden;white-space:nowrap;text-overflow:ellipsis;pointer-events:none}
.ng-animate-disabled .mat-badge-content,.mat-badge-content._mat-animation-noopable{transition:none}
.mat-badge-content.mat-badge-active{transform:none}
.mat-badge-small .mat-badge-content{width:16px;height:16px;line-height:16px}
.mat-badge-small.mat-badge-above .mat-badge-content{top:-8px}
.mat-badge-small.mat-badge-below .mat-badge-content{bottom:-8px}
.mat-badge-small.mat-badge-before .mat-badge-content{left:-16px}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content{left:auto;right:-16px}
.mat-badge-small.mat-badge-after .mat-badge-content{right:-16px}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content{right:auto;left:-16px}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content{left:-8px}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content{left:auto;right:-8px}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content{right:-8px}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content{right:auto;left:-8px}
.mat-badge-medium .mat-badge-content{width:22px;height:22px;line-height:22px}
.mat-badge-medium.mat-badge-above .mat-badge-content{top:-11px}
.mat-badge-medium.mat-badge-below .mat-badge-content{bottom:-11px}
.mat-badge-medium.mat-badge-before .mat-badge-content{left:-22px}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content{left:auto;right:-22px}
.mat-badge-medium.mat-badge-after .mat-badge-content{right:-22px}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content{right:auto;left:-22px}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content{left:-11px}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content{left:auto;right:-11px}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content{right:-11px}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content{right:auto;left:-11px}
.mat-badge-large .mat-badge-content{width:28px;height:28px;line-height:28px}
.mat-badge-large.mat-badge-above .mat-badge-content{top:-14px}
.mat-badge-large.mat-badge-below .mat-badge-content{bottom:-14px}
.mat-badge-large.mat-badge-before .mat-badge-content{left:-28px}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content{left:auto;right:-28px}
.mat-badge-large.mat-badge-after .mat-badge-content{right:-28px}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content{right:auto;left:-28px}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content{left:-14px}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content{left:auto;right:-14px}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content{right:-14px}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content{right:auto;left:-14px}
.mat-bottom-sheet-container{box-shadow:0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12);background:#fff;color:rgba(0,0,0,.87)}
.mat-button,.mat-icon-button,.mat-stroked-button{color:inherit;background:transparent}
.mat-button.mat-primary,.mat-icon-button.mat-primary,.mat-stroked-button.mat-primary{color:#3f51b5}
.mat-button.mat-accent,.mat-icon-button.mat-accent,.mat-stroked-button.mat-accent{color:#ff4081}
.mat-button.mat-warn,.mat-icon-button.mat-warn,.mat-stroked-button.mat-warn{color:#f44336}
.mat-button.mat-primary.mat-button-disabled,.mat-button.mat-accent.mat-button-disabled,.mat-button.mat-warn.mat-button-disabled,.mat-button.mat-button-disabled.mat-button-disabled,.mat-icon-button.mat-primary.mat-button-disabled,.mat-icon-button.mat-accent.mat-button-disabled,.mat-icon-button.mat-warn.mat-button-disabled,.mat-icon-button.mat-button-disabled.mat-button-disabled,.mat-stroked-button.mat-primary.mat-button-disabled,.mat-stroked-button.mat-accent.mat-button-disabled,.mat-stroked-button.mat-warn.mat-button-disabled,.mat-stroked-button.mat-button-disabled.mat-button-disabled{color:rgba(0,0,0,.26)}
.mat-button.mat-primary .mat-button-focus-overlay,.mat-icon-button.mat-primary .mat-button-focus-overlay,.mat-stroked-button.mat-primary .mat-button-focus-overlay{background-color:#3f51b5}
.mat-button.mat-accent .mat-button-focus-overlay,.mat-icon-button.mat-accent .mat-button-focus-overlay,.mat-stroked-button.mat-accent .mat-button-focus-overlay{background-color:#ff4081}
.mat-button.mat-warn .mat-button-focus-overlay,.mat-icon-button.mat-warn .mat-button-focus-overlay,.mat-stroked-button.mat-warn .mat-button-focus-overlay{background-color:#f44336}
.mat-button.mat-button-disabled .mat-button-focus-overlay,.mat-icon-button.mat-button-disabled .mat-button-focus-overlay,.mat-stroked-button.mat-button-disabled .mat-button-focus-overlay{background-color:transparent}
.mat-button .mat-ripple-element,.mat-icon-button .mat-ripple-element,.mat-stroked-button .mat-ripple-element{opacity:.1;background-color:currentColor}
.mat-button-focus-overlay{background:#000}
.mat-stroked-button:not(.mat-button-disabled){border-color:rgba(0,0,0,.12)}
.mat-flat-button,.mat-raised-button,.mat-fab,.mat-mini-fab{color:rgba(0,0,0,.87);background-color:#fff}
.mat-flat-button.mat-primary,.mat-raised-button.mat-primary,.mat-fab.mat-primary,.mat-mini-fab.mat-primary{color:#fff}
.mat-flat-button.mat-accent,.mat-raised-button.mat-accent,.mat-fab.mat-accent,.mat-mini-fab.mat-accent{color:#fff}
.mat-flat-button.mat-warn,.mat-raised-button.mat-warn,.mat-fab.mat-warn,.mat-mini-fab.mat-warn{color:#fff}
.mat-flat-button.mat-primary.mat-button-disabled,.mat-flat-button.mat-accent.mat-button-disabled,.mat-flat-button.mat-warn.mat-button-disabled,.mat-flat-button.mat-button-disabled.mat-button-disabled,.mat-raised-button.mat-primary.mat-button-disabled,.mat-raised-button.mat-accent.mat-button-disabled,.mat-raised-button.mat-warn.mat-button-disabled,.mat-raised-button.mat-button-disabled.mat-button-disabled,.mat-fab.mat-primary.mat-button-disabled,.mat-fab.mat-accent.mat-button-disabled,.mat-fab.mat-warn.mat-button-disabled,.mat-fab.mat-button-disabled.mat-button-disabled,.mat-mini-fab.mat-primary.mat-button-disabled,.mat-mini-fab.mat-accent.mat-button-disabled,.mat-mini-fab.mat-warn.mat-button-disabled,.mat-mini-fab.mat-button-disabled.mat-button-disabled{color:rgba(0,0,0,.26)}
.mat-flat-button.mat-primary,.mat-raised-button.mat-primary,.mat-fab.mat-primary,.mat-mini-fab.mat-primary{background-color:#3f51b5}
.mat-flat-button.mat-accent,.mat-raised-button.mat-accent,.mat-fab.mat-accent,.mat-mini-fab.mat-accent{background-color:#ff4081}
.mat-flat-button.mat-warn,.mat-raised-button.mat-warn,.mat-fab.mat-warn,.mat-mini-fab.mat-warn{background-color:#f44336}
.mat-flat-button.mat-primary.mat-button-disabled,.mat-flat-button.mat-accent.mat-button-disabled,.mat-flat-button.mat-warn.mat-button-disabled,.mat-flat-button.mat-button-disabled.mat-button-disabled,.mat-raised-button.mat-primary.mat-button-disabled,.mat-raised-button.mat-accent.mat-button-disabled,.mat-raised-button.mat-warn.mat-button-disabled,.mat-raised-button.mat-button-disabled.mat-button-disabled,.mat-fab.mat-primary.mat-button-disabled,.mat-fab.mat-accent.mat-button-disabled,.mat-fab.mat-warn.mat-button-disabled,.mat-fab.mat-button-disabled.mat-button-disabled,.mat-mini-fab.mat-primary.mat-button-disabled,.mat-mini-fab.mat-accent.mat-button-disabled,.mat-mini-fab.mat-warn.mat-button-disabled,.mat-mini-fab.mat-button-disabled.mat-button-disabled{background-color:rgba(0,0,0,.12)}
.mat-flat-button.mat-primary .mat-ripple-element,.mat-raised-button.mat-primary .mat-ripple-element,.mat-fab.mat-primary .mat-ripple-element,.mat-mini-fab.mat-primary .mat-ripple-element{background-color:rgba(255,255,255,.1)}
.mat-flat-button.mat-accent .mat-ripple-element,.mat-raised-button.mat-accent .mat-ripple-element,.mat-fab.mat-accent .mat-ripple-element,.mat-mini-fab.mat-accent .mat-ripple-element{background-color:rgba(255,255,255,.1)}
.mat-flat-button.mat-warn .mat-ripple-element,.mat-raised-button.mat-warn .mat-ripple-element,.mat-fab.mat-warn .mat-ripple-element,.mat-mini-fab.mat-warn .mat-ripple-element{background-color:rgba(255,255,255,.1)}
.mat-stroked-button:not([class*=mat-elevation-z]),.mat-flat-button:not([class*=mat-elevation-z]){box-shadow:0px 0px 0px 0px rgba(0, 0, 0, 0.2),0px 0px 0px 0px rgba(0, 0, 0, 0.14),0px 0px 0px 0px rgba(0, 0, 0, 0.12)}
.mat-raised-button:not([class*=mat-elevation-z]){box-shadow:0px 3px 1px -2px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 1px 5px 0px rgba(0, 0, 0, 0.12)}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]){box-shadow:0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12)}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]){box-shadow:0px 0px 0px 0px rgba(0, 0, 0, 0.2),0px 0px 0px 0px rgba(0, 0, 0, 0.14),0px 0px 0px 0px rgba(0, 0, 0, 0.12)}
.mat-fab:not([class*=mat-elevation-z]),.mat-mini-fab:not([class*=mat-elevation-z]){box-shadow:0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]),.mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]){box-shadow:0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 12px 17px 2px rgba(0, 0, 0, 0.14),0px 5px 22px 4px rgba(0, 0, 0, 0.12)}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]),.mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]){box-shadow:0px 0px 0px 0px rgba(0, 0, 0, 0.2),0px 0px 0px 0px rgba(0, 0, 0, 0.14),0px 0px 0px 0px rgba(0, 0, 0, 0.12)}
.mat-button-toggle-standalone,.mat-button-toggle-group{box-shadow:0px 3px 1px -2px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 1px 5px 0px rgba(0, 0, 0, 0.12)}
.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,.mat-button-toggle-group-appearance-standard{box-shadow:none}
.mat-button-toggle{color:rgba(0,0,0,.38)}
.mat-button-toggle .mat-button-toggle-focus-overlay{background-color:rgba(0,0,0,.12)}
.mat-button-toggle-appearance-standard{color:rgba(0,0,0,.87);background:#fff}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay{background-color:#000}
.mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle{border-left:solid 1px rgba(0,0,0,.12)}
[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle{border-left:none;border-right:solid 1px rgba(0,0,0,.12)}
.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle+.mat-button-toggle{border-left:none;border-right:none;border-top:solid 1px rgba(0,0,0,.12)}
.mat-button-toggle-checked{background-color:#e0e0e0;color:rgba(0,0,0,.54)}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard{color:rgba(0,0,0,.87)}
.mat-button-toggle-disabled{color:rgba(0,0,0,.26);background-color:#eee}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard{background:#fff}
.mat-button-toggle-disabled.mat-button-toggle-checked{background-color:#bdbdbd}
.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,.mat-button-toggle-group-appearance-standard{border:solid 1px rgba(0,0,0,.12)}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content{line-height:48px}
.mat-card{background:#fff;color:rgba(0,0,0,.87)}
.mat-card:not([class*=mat-elevation-z]){box-shadow:0px 2px 1px -1px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 1px 3px 0px rgba(0, 0, 0, 0.12)}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]){box-shadow:0px 0px 0px 0px rgba(0, 0, 0, 0.2),0px 0px 0px 0px rgba(0, 0, 0, 0.14),0px 0px 0px 0px rgba(0, 0, 0, 0.12)}
.mat-card-subtitle{color:rgba(0,0,0,.54)}
.mat-checkbox-frame{border-color:rgba(0,0,0,.54)}
.mat-checkbox-checkmark{fill:#fafafa}
.mat-checkbox-checkmark-path{stroke:#fafafa !important}
.mat-checkbox-mixedmark{background-color:#fafafa}
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,.mat-checkbox-checked.mat-primary .mat-checkbox-background{background-color:#3f51b5}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,.mat-checkbox-checked.mat-accent .mat-checkbox-background{background-color:#ff4081}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background,.mat-checkbox-checked.mat-warn .mat-checkbox-background{background-color:#f44336}
.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background{background-color:#b0b0b0}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame{border-color:#b0b0b0}
.mat-checkbox-disabled .mat-checkbox-label{color:rgba(0,0,0,.54)}
.mat-checkbox .mat-ripple-element{background-color:#000}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element{background:#3f51b5}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element{background:#ff4081}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element{background:#f44336}
.mat-chip.mat-standard-chip{background-color:#e0e0e0;color:rgba(0,0,0,.87)}
.mat-chip.mat-standard-chip .mat-chip-remove{color:rgba(0,0,0,.87);opacity:.4}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active{box-shadow:0px 3px 3px -2px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 1px 8px 0px rgba(0, 0, 0, 0.12)}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover{opacity:.54}
.mat-chip.mat-standard-chip.mat-chip-disabled{opacity:.4}
.mat-chip.mat-standard-chip::after{background:#000}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary{background-color:#3f51b5;color:#fff}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove{color:#fff;opacity:.4}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element{background-color:rgba(255,255,255,.1)}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn{background-color:#f44336;color:#fff}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove{color:#fff;opacity:.4}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element{background-color:rgba(255,255,255,.1)}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent{background-color:#ff4081;color:#fff}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove{color:#fff;opacity:.4}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element{background-color:rgba(255,255,255,.1)}
.mat-table{background:#fff}
.mat-table thead,.mat-table tbody,.mat-table tfoot,mat-header-row,mat-row,mat-footer-row,[mat-header-row],[mat-row],[mat-footer-row],.mat-table-sticky{background:inherit}
mat-row,mat-header-row,mat-footer-row,th.mat-header-cell,td.mat-cell,td.mat-footer-cell{border-bottom-color:rgba(0,0,0,.12)}
.mat-header-cell{color:rgba(0,0,0,.54)}
.mat-cell,.mat-footer-cell{color:rgba(0,0,0,.87)}
.mat-calendar-arrow{border-top-color:rgba(0,0,0,.54)}
.mat-datepicker-toggle,.mat-datepicker-content .mat-calendar-next-button,.mat-datepicker-content .mat-calendar-previous-button{color:rgba(0,0,0,.54)}
.mat-calendar-table-header{color:rgba(0,0,0,.38)}
.mat-calendar-table-header-divider::after{background:rgba(0,0,0,.12)}
.mat-calendar-body-label{color:rgba(0,0,0,.54)}
.mat-calendar-body-cell-content,.mat-date-range-input-separator{color:rgba(0,0,0,.87);border-color:transparent}
.mat-calendar-body-disabled>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){color:rgba(0,0,0,.38)}
.mat-form-field-disabled .mat-date-range-input-separator{color:rgba(0,0,0,.38)}
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),.cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),.cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){background-color:rgba(0,0,0,.04)}
.mat-calendar-body-in-preview{color:rgba(0,0,0,.24)}
.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){border-color:rgba(0,0,0,.38)}
.mat-calendar-body-disabled>.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){border-color:rgba(0,0,0,.18)}
.mat-calendar-body-in-range::before{background:rgba(63,81,181,.2)}
.mat-calendar-body-comparison-identical,.mat-calendar-body-in-comparison-range::before{background:rgba(249,171,0,.2)}
.mat-calendar-body-comparison-bridge-start::before,[dir=rtl] .mat-calendar-body-comparison-bridge-end::before{background:linear-gradient(to right, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%)}
.mat-calendar-body-comparison-bridge-end::before,[dir=rtl] .mat-calendar-body-comparison-bridge-start::before{background:linear-gradient(to left, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%)}
.mat-calendar-body-in-range>.mat-calendar-body-comparison-identical,.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after{background:#a8dab5}
.mat-calendar-body-comparison-identical.mat-calendar-body-selected,.mat-calendar-body-in-comparison-range>.mat-calendar-body-selected{background:#46a35e}
.mat-calendar-body-selected{background-color:#3f51b5;color:#fff}
.mat-calendar-body-disabled>.mat-calendar-body-selected{background-color:rgba(63,81,181,.4)}
.mat-calendar-body-today.mat-calendar-body-selected{box-shadow:inset 0 0 0 1px #fff}
.mat-datepicker-content{box-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12);background-color:#fff;color:rgba(0,0,0,.87)}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before{background:rgba(255,64,129,.2)}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before{background:rgba(249,171,0,.2)}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before{background:linear-gradient(to right, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%)}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before{background:linear-gradient(to left, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%)}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range>.mat-calendar-body-comparison-identical,.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after{background:#a8dab5}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range>.mat-calendar-body-selected{background:#46a35e}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected{background-color:#ff4081;color:#fff}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled>.mat-calendar-body-selected{background-color:rgba(255,64,129,.4)}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected{box-shadow:inset 0 0 0 1px #fff}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before{background:rgba(244,67,54,.2)}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before{background:rgba(249,171,0,.2)}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before{background:linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%)}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before{background:linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%)}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range>.mat-calendar-body-comparison-identical,.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after{background:#a8dab5}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range>.mat-calendar-body-selected{background:#46a35e}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected{background-color:#f44336;color:#fff}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled>.mat-calendar-body-selected{background-color:rgba(244,67,54,.4)}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected{box-shadow:inset 0 0 0 1px #fff}
.mat-datepicker-content-touch{box-shadow:0px 0px 0px 0px rgba(0, 0, 0, 0.2),0px 0px 0px 0px rgba(0, 0, 0, 0.14),0px 0px 0px 0px rgba(0, 0, 0, 0.12)}
.mat-datepicker-toggle-active{color:#3f51b5}
.mat-datepicker-toggle-active.mat-accent{color:#ff4081}
.mat-datepicker-toggle-active.mat-warn{color:#f44336}
.mat-date-range-input-inner[disabled]{color:rgba(0,0,0,.38)}
.mat-dialog-container{box-shadow:0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12);background:#fff;color:rgba(0,0,0,.87)}
.mat-divider{border-top-color:rgba(0,0,0,.12)}
.mat-divider-vertical{border-right-color:rgba(0,0,0,.12)}
.mat-expansion-panel{background:#fff;color:rgba(0,0,0,.87)}
.mat-expansion-panel:not([class*=mat-elevation-z]){box-shadow:0px 3px 1px -2px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 1px 5px 0px rgba(0, 0, 0, 0.12)}
.mat-action-row{border-top-color:rgba(0,0,0,.12)}
.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]),.mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]),.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]){background:rgba(0,0,0,.04)}
@media(hover: none){.mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover{background:#fff}}
.mat-expansion-panel-header-title{color:rgba(0,0,0,.87)}
.mat-expansion-panel-header-description,.mat-expansion-indicator::after{color:rgba(0,0,0,.54)}
.mat-expansion-panel-header[aria-disabled=true]{color:rgba(0,0,0,.26)}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description{color:inherit}
.mat-expansion-panel-header{height:48px}
.mat-expansion-panel-header.mat-expanded{height:64px}
.mat-form-field-label{color:rgba(0,0,0,.6)}
.mat-hint{color:rgba(0,0,0,.6)}
.mat-form-field.mat-focused .mat-form-field-label{color:#3f51b5}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent{color:#ff4081}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn{color:#f44336}
.mat-focused .mat-form-field-required-marker{color:#ff4081}
.mat-form-field-ripple{background-color:rgba(0,0,0,.87)}
.mat-form-field.mat-focused .mat-form-field-ripple{background-color:#3f51b5}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent{background-color:#ff4081}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn{background-color:#f44336}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after{color:#3f51b5}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after{color:#ff4081}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after{color:#f44336}
.mat-form-field.mat-form-field-invalid .mat-form-field-label{color:#f44336}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker{color:#f44336}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent{background-color:#f44336}
.mat-error{color:#f44336}
.mat-form-field-appearance-legacy .mat-form-field-label{color:rgba(0,0,0,.54)}
.mat-form-field-appearance-legacy .mat-hint{color:rgba(0,0,0,.54)}
.mat-form-field-appearance-legacy .mat-form-field-underline{background-color:rgba(0,0,0,.42)}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline{background-image:linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);background-size:4px 100%;background-repeat:repeat-x}
.mat-form-field-appearance-standard .mat-form-field-underline{background-color:rgba(0,0,0,.42)}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline{background-image:linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);background-size:4px 100%;background-repeat:repeat-x}
.mat-form-field-appearance-fill .mat-form-field-flex{background-color:rgba(0,0,0,.04)}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex{background-color:rgba(0,0,0,.02)}
.mat-form-field-appearance-fill .mat-form-field-underline::before{background-color:rgba(0,0,0,.42)}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label{color:rgba(0,0,0,.38)}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before{background-color:transparent}
.mat-form-field-appearance-outline .mat-form-field-outline{color:rgba(0,0,0,.12)}
.mat-form-field-appearance-outline .mat-form-field-outline-thick{color:rgba(0,0,0,.87)}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick{color:#3f51b5}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick{color:#ff4081}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick{color:#f44336}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick{color:#f44336}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label{color:rgba(0,0,0,.38)}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline{color:rgba(0,0,0,.06)}
.mat-icon.mat-primary{color:#3f51b5}
.mat-icon.mat-accent{color:#ff4081}
.mat-icon.mat-warn{color:#f44336}
.mat-form-field-type-mat-native-select .mat-form-field-infix::after{color:rgba(0,0,0,.54)}
.mat-input-element:disabled,.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after{color:rgba(0,0,0,.38)}
.mat-input-element{caret-color:#3f51b5}
.mat-input-element::placeholder{color:rgba(0,0,0,.42)}
.mat-input-element::-moz-placeholder{color:rgba(0,0,0,.42)}
.mat-input-element::-webkit-input-placeholder{color:rgba(0,0,0,.42)}
.mat-input-element:-ms-input-placeholder{color:rgba(0,0,0,.42)}
.mat-form-field.mat-accent .mat-input-element{caret-color:#ff4081}
.mat-form-field.mat-warn .mat-input-element,.mat-form-field-invalid .mat-input-element{caret-color:#f44336}
.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after{color:#f44336}
.mat-list-base .mat-list-item{color:rgba(0,0,0,.87)}
.mat-list-base .mat-list-option{color:rgba(0,0,0,.87)}
.mat-list-base .mat-subheader{color:rgba(0,0,0,.54)}
.mat-list-item-disabled{background-color:#eee}
.mat-list-option:hover,.mat-list-option:focus,.mat-nav-list .mat-list-item:hover,.mat-nav-list .mat-list-item:focus,.mat-action-list .mat-list-item:hover,.mat-action-list .mat-list-item:focus{background:rgba(0,0,0,.04)}
.mat-list-single-selected-option,.mat-list-single-selected-option:hover,.mat-list-single-selected-option:focus{background:rgba(0,0,0,.12)}
.mat-menu-panel{background:#fff}
.mat-menu-panel:not([class*=mat-elevation-z]){box-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)}
.mat-menu-item{background:transparent;color:rgba(0,0,0,.87)}
.mat-menu-item[disabled],.mat-menu-item[disabled]::after,.mat-menu-item[disabled] .mat-icon-no-color{color:rgba(0,0,0,.38)}
.mat-menu-item .mat-icon-no-color,.mat-menu-item-submenu-trigger::after{color:rgba(0,0,0,.54)}
.mat-menu-item:hover:not([disabled]),.mat-menu-item.cdk-program-focused:not([disabled]),.mat-menu-item.cdk-keyboard-focused:not([disabled]),.mat-menu-item-highlighted:not([disabled]){background:rgba(0,0,0,.04)}
.mat-paginator{background:#fff}
.mat-paginator,.mat-paginator-page-size .mat-select-trigger{color:rgba(0,0,0,.54)}
.mat-paginator-decrement,.mat-paginator-increment{border-top:2px solid rgba(0,0,0,.54);border-right:2px solid rgba(0,0,0,.54)}
.mat-paginator-first,.mat-paginator-last{border-top:2px solid rgba(0,0,0,.54)}
.mat-icon-button[disabled] .mat-paginator-decrement,.mat-icon-button[disabled] .mat-paginator-increment,.mat-icon-button[disabled] .mat-paginator-first,.mat-icon-button[disabled] .mat-paginator-last{border-color:rgba(0,0,0,.38)}
.mat-paginator-container{min-height:56px}
.mat-progress-bar-background{fill:#c5cae9}
.mat-progress-bar-buffer{background-color:#c5cae9}
.mat-progress-bar-fill::after{background-color:#3f51b5}
.mat-progress-bar.mat-accent .mat-progress-bar-background{fill:#ff80ab}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer{background-color:#ff80ab}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after{background-color:#ff4081}
.mat-progress-bar.mat-warn .mat-progress-bar-background{fill:#ffcdd2}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer{background-color:#ffcdd2}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after{background-color:#f44336}
.mat-progress-spinner circle,.mat-spinner circle{stroke:#3f51b5}
.mat-progress-spinner.mat-accent circle,.mat-spinner.mat-accent circle{stroke:#ff4081}
.mat-progress-spinner.mat-warn circle,.mat-spinner.mat-warn circle{stroke:#f44336}
.mat-radio-outer-circle{border-color:rgba(0,0,0,.54)}
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle{border-color:#3f51b5}
.mat-radio-button.mat-primary .mat-radio-inner-circle,.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple{background-color:#3f51b5}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{border-color:#ff4081}
.mat-radio-button.mat-accent .mat-radio-inner-circle,.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple{background-color:#ff4081}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle{border-color:#f44336}
.mat-radio-button.mat-warn .mat-radio-inner-circle,.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple,.mat-radio-button.mat-warn:active .mat-radio-persistent-ripple{background-color:#f44336}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle{border-color:rgba(0,0,0,.38)}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle{background-color:rgba(0,0,0,.38)}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content{color:rgba(0,0,0,.38)}
.mat-radio-button .mat-ripple-element{background-color:#000}
.mat-select-value{color:rgba(0,0,0,.87)}
.mat-select-placeholder{color:rgba(0,0,0,.42)}
.mat-select-disabled .mat-select-value{color:rgba(0,0,0,.38)}
.mat-select-arrow{color:rgba(0,0,0,.54)}
.mat-select-panel{background:#fff}
.mat-select-panel:not([class*=mat-elevation-z]){box-shadow:0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple){background:rgba(0,0,0,.12)}
.mat-form-field.mat-focused.mat-primary .mat-select-arrow{color:#3f51b5}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow{color:#ff4081}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow{color:#f44336}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow{color:#f44336}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow{color:rgba(0,0,0,.38)}
.mat-drawer-container{background-color:#fafafa;color:rgba(0,0,0,.87)}
.mat-drawer{background-color:#fff;color:rgba(0,0,0,.87)}
.mat-drawer.mat-drawer-push{background-color:#fff}
.mat-drawer:not(.mat-drawer-side){box-shadow:0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12)}
.mat-drawer-side{border-right:solid 1px rgba(0,0,0,.12)}
.mat-drawer-side.mat-drawer-end{border-left:solid 1px rgba(0,0,0,.12);border-right:none}
[dir=rtl] .mat-drawer-side{border-left:solid 1px rgba(0,0,0,.12);border-right:none}
[dir=rtl] .mat-drawer-side.mat-drawer-end{border-left:none;border-right:solid 1px rgba(0,0,0,.12)}
.mat-drawer-backdrop.mat-drawer-shown{background-color:rgba(0,0,0,.6)}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb{background-color:#ff4081}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar{background-color:rgba(255,64,129,.54)}
.mat-slide-toggle.mat-checked .mat-ripple-element{background-color:#ff4081}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb{background-color:#3f51b5}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar{background-color:rgba(63,81,181,.54)}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element{background-color:#3f51b5}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb{background-color:#f44336}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar{background-color:rgba(244,67,54,.54)}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element{background-color:#f44336}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element{background-color:#000}
.mat-slide-toggle-thumb{box-shadow:0px 2px 1px -1px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 1px 3px 0px rgba(0, 0, 0, 0.12);background-color:#fafafa}
.mat-slide-toggle-bar{background-color:rgba(0,0,0,.38)}
.mat-slider-track-background{background-color:rgba(0,0,0,.26)}
.mat-primary .mat-slider-track-fill,.mat-primary .mat-slider-thumb,.mat-primary .mat-slider-thumb-label{background-color:#3f51b5}
.mat-primary .mat-slider-thumb-label-text{color:#fff}
.mat-primary .mat-slider-focus-ring{background-color:rgba(63,81,181,.2)}
.mat-accent .mat-slider-track-fill,.mat-accent .mat-slider-thumb,.mat-accent .mat-slider-thumb-label{background-color:#ff4081}
.mat-accent .mat-slider-thumb-label-text{color:#fff}
.mat-accent .mat-slider-focus-ring{background-color:rgba(255,64,129,.2)}
.mat-warn .mat-slider-track-fill,.mat-warn .mat-slider-thumb,.mat-warn .mat-slider-thumb-label{background-color:#f44336}
.mat-warn .mat-slider-thumb-label-text{color:#fff}
.mat-warn .mat-slider-focus-ring{background-color:rgba(244,67,54,.2)}
.mat-slider:hover .mat-slider-track-background,.cdk-focused .mat-slider-track-background{background-color:rgba(0,0,0,.38)}
.mat-slider-disabled .mat-slider-track-background,.mat-slider-disabled .mat-slider-track-fill,.mat-slider-disabled .mat-slider-thumb{background-color:rgba(0,0,0,.26)}
.mat-slider-disabled:hover .mat-slider-track-background{background-color:rgba(0,0,0,.26)}
.mat-slider-min-value .mat-slider-focus-ring{background-color:rgba(0,0,0,.12)}
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label{background-color:rgba(0,0,0,.87)}
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label{background-color:rgba(0,0,0,.26)}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb{border-color:rgba(0,0,0,.26);background-color:transparent}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb,.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb{border-color:rgba(0,0,0,.38)}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb,.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb{border-color:rgba(0,0,0,.26)}
.mat-slider-has-ticks .mat-slider-wrapper::after{border-color:rgba(0,0,0,.7)}
.mat-slider-horizontal .mat-slider-ticks{background-image:repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);background-image:-moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent)}
.mat-slider-vertical .mat-slider-ticks{background-image:repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent)}
.mat-step-header.cdk-keyboard-focused,.mat-step-header.cdk-program-focused,.mat-step-header:hover{background-color:rgba(0,0,0,.04)}
@media(hover: none){.mat-step-header:hover{background:none}}
.mat-step-header .mat-step-label,.mat-step-header .mat-step-optional{color:rgba(0,0,0,.54)}
.mat-step-header .mat-step-icon{background-color:rgba(0,0,0,.54);color:#fff}
.mat-step-header .mat-step-icon-selected,.mat-step-header .mat-step-icon-state-done,.mat-step-header .mat-step-icon-state-edit{background-color:#3f51b5;color:#fff}
.mat-step-header.mat-accent .mat-step-icon{color:#fff}
.mat-step-header.mat-accent .mat-step-icon-selected,.mat-step-header.mat-accent .mat-step-icon-state-done,.mat-step-header.mat-accent .mat-step-icon-state-edit{background-color:#ff4081;color:#fff}
.mat-step-header.mat-warn .mat-step-icon{color:#fff}
.mat-step-header.mat-warn .mat-step-icon-selected,.mat-step-header.mat-warn .mat-step-icon-state-done,.mat-step-header.mat-warn .mat-step-icon-state-edit{background-color:#f44336;color:#fff}
.mat-step-header .mat-step-icon-state-error{background-color:transparent;color:#f44336}
.mat-step-header .mat-step-label.mat-step-label-active{color:rgba(0,0,0,.87)}
.mat-step-header .mat-step-label.mat-step-label-error{color:#f44336}
.mat-stepper-horizontal,.mat-stepper-vertical{background-color:#fff}
.mat-stepper-vertical-line::before{border-left-color:rgba(0,0,0,.12)}
.mat-horizontal-stepper-header::before,.mat-horizontal-stepper-header::after,.mat-stepper-horizontal-line{border-top-color:rgba(0,0,0,.12)}
.mat-horizontal-stepper-header{height:72px}
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,.mat-vertical-stepper-header{padding:24px 24px}
.mat-stepper-vertical-line::before{top:-16px;bottom:-16px}
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after,.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before{top:36px}
.mat-stepper-label-position-bottom .mat-stepper-horizontal-line{top:36px}
.mat-sort-header-arrow{color:#757575}
.mat-tab-nav-bar,.mat-tab-header{border-bottom:1px solid rgba(0,0,0,.12)}
.mat-tab-group-inverted-header .mat-tab-nav-bar,.mat-tab-group-inverted-header .mat-tab-header{border-top:1px solid rgba(0,0,0,.12);border-bottom:none}
.mat-tab-label,.mat-tab-link{color:rgba(0,0,0,.87)}
.mat-tab-label.mat-tab-disabled,.mat-tab-link.mat-tab-disabled{color:rgba(0,0,0,.38)}
.mat-tab-header-pagination-chevron{border-color:rgba(0,0,0,.87)}
.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron{border-color:rgba(0,0,0,.38)}
.mat-tab-group[class*=mat-background-] .mat-tab-header,.mat-tab-nav-bar[class*=mat-background-]{border-bottom:none;border-top:none}
.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled){background-color:rgba(197,202,233,.3)}
.mat-tab-group.mat-primary .mat-ink-bar,.mat-tab-nav-bar.mat-primary .mat-ink-bar{background-color:#3f51b5}
.mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar,.mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar{background-color:#fff}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled){background-color:rgba(255,128,171,.3)}
.mat-tab-group.mat-accent .mat-ink-bar,.mat-tab-nav-bar.mat-accent .mat-ink-bar{background-color:#ff4081}
.mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar,.mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar{background-color:#fff}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled){background-color:rgba(255,205,210,.3)}
.mat-tab-group.mat-warn .mat-ink-bar,.mat-tab-nav-bar.mat-warn .mat-ink-bar{background-color:#f44336}
.mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar,.mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar{background-color:#fff}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled){background-color:rgba(197,202,233,.3)}
.mat-tab-group.mat-background-primary .mat-tab-header,.mat-tab-group.mat-background-primary .mat-tab-links,.mat-tab-group.mat-background-primary .mat-tab-header-pagination,.mat-tab-nav-bar.mat-background-primary .mat-tab-header,.mat-tab-nav-bar.mat-background-primary .mat-tab-links,.mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination{background-color:#3f51b5}
.mat-tab-group.mat-background-primary .mat-tab-label,.mat-tab-group.mat-background-primary .mat-tab-link,.mat-tab-nav-bar.mat-background-primary .mat-tab-label,.mat-tab-nav-bar.mat-background-primary .mat-tab-link{color:#fff}
.mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled,.mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled,.mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled,.mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled{color:rgba(255,255,255,.4)}
.mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron,.mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron{border-color:#fff}
.mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,.mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron{border-color:rgba(255,255,255,.4)}
.mat-tab-group.mat-background-primary .mat-ripple-element,.mat-tab-nav-bar.mat-background-primary .mat-ripple-element{background-color:rgba(255,255,255,.12)}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled){background-color:rgba(255,128,171,.3)}
.mat-tab-group.mat-background-accent .mat-tab-header,.mat-tab-group.mat-background-accent .mat-tab-links,.mat-tab-group.mat-background-accent .mat-tab-header-pagination,.mat-tab-nav-bar.mat-background-accent .mat-tab-header,.mat-tab-nav-bar.mat-background-accent .mat-tab-links,.mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination{background-color:#ff4081}
.mat-tab-group.mat-background-accent .mat-tab-label,.mat-tab-group.mat-background-accent .mat-tab-link,.mat-tab-nav-bar.mat-background-accent .mat-tab-label,.mat-tab-nav-bar.mat-background-accent .mat-tab-link{color:#fff}
.mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled,.mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled,.mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled,.mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled{color:rgba(255,255,255,.4)}
.mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron,.mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron{border-color:#fff}
.mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,.mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron{border-color:rgba(255,255,255,.4)}
.mat-tab-group.mat-background-accent .mat-ripple-element,.mat-tab-nav-bar.mat-background-accent .mat-ripple-element{background-color:rgba(255,255,255,.12)}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled){background-color:rgba(255,205,210,.3)}
.mat-tab-group.mat-background-warn .mat-tab-header,.mat-tab-group.mat-background-warn .mat-tab-links,.mat-tab-group.mat-background-warn .mat-tab-header-pagination,.mat-tab-nav-bar.mat-background-warn .mat-tab-header,.mat-tab-nav-bar.mat-background-warn .mat-tab-links,.mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination{background-color:#f44336}
.mat-tab-group.mat-background-warn .mat-tab-label,.mat-tab-group.mat-background-warn .mat-tab-link,.mat-tab-nav-bar.mat-background-warn .mat-tab-label,.mat-tab-nav-bar.mat-background-warn .mat-tab-link{color:#fff}
.mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled,.mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled,.mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled,.mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled{color:rgba(255,255,255,.4)}
.mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron,.mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron{border-color:#fff}
.mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,.mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron{border-color:rgba(255,255,255,.4)}
.mat-tab-group.mat-background-warn .mat-ripple-element,.mat-tab-nav-bar.mat-background-warn .mat-ripple-element{background-color:rgba(255,255,255,.12)}
.mat-toolbar{background:#f5f5f5;color:rgba(0,0,0,.87)}
.mat-toolbar.mat-primary{background:#3f51b5;color:#fff}
.mat-toolbar.mat-accent{background:#ff4081;color:#fff}
.mat-toolbar.mat-warn{background:#f44336;color:#fff}
.mat-toolbar .mat-form-field-underline,.mat-toolbar .mat-form-field-ripple,.mat-toolbar .mat-focused .mat-form-field-ripple{background-color:currentColor}
.mat-toolbar .mat-form-field-label,.mat-toolbar .mat-focused .mat-form-field-label,.mat-toolbar .mat-select-value,.mat-toolbar .mat-select-arrow,.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow{color:inherit}
.mat-toolbar .mat-input-element{caret-color:currentColor}
.mat-toolbar-multiple-rows{min-height:64px}
.mat-toolbar-row,.mat-toolbar-single-row{height:64px}
@media(max-width: 599px){.mat-toolbar-multiple-rows{min-height:56px}.mat-toolbar-row,.mat-toolbar-single-row{height:56px}}
.mat-tooltip{background:rgba(97,97,97,.9)}
.mat-tree{background:#fff}
.mat-tree-node,.mat-nested-tree-node{color:rgba(0,0,0,.87)}
.mat-tree-node{min-height:48px}
.mat-snack-bar-container{color:rgba(255,255,255,.7);background:#323232;box-shadow:0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)}
.mat-simple-snackbar-action{color:#ff4081}
/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 12px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}
/* toast styles */
.toast-title {
  font-weight: bold;
}
.toast-message {
  word-wrap: break-word;
}
.toast-message a,
.toast-message label {
  color: #FFFFFF;
}
.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}
.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}
.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}
/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}
.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}
.toast-container * {
  box-sizing: border-box;
}
.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}
.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}
.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}
.toast-success {
  background-color: #51A351;
}
.toast-error {
  background-color: #BD362F;
}
.toast-info {
  background-color: #2F96B4;
}
.toast-warning {
  background-color: #F89406;
}
.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}
/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
.cdk-global-overlay-wrapper,.cdk-overlay-container{pointer-events:none;top:0;left:0;height:100%;width:100%}
.cdk-overlay-container{position:fixed;z-index:1000}
.cdk-overlay-container:empty{display:none}
.cdk-global-overlay-wrapper{display:flex;position:absolute;z-index:1000}
.cdk-overlay-pane{position:absolute;pointer-events:auto;box-sizing:border-box;z-index:1000;display:flex;max-width:100%;max-height:100%}
.cdk-overlay-backdrop{position:absolute;top:0;bottom:0;left:0;right:0;z-index:1000;pointer-events:auto;-webkit-tap-highlight-color:transparent;transition:opacity .4s cubic-bezier(.25,.8,.25,1);opacity:0}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing{opacity:1}
@media screen and (-ms-high-contrast:active){.cdk-overlay-backdrop.cdk-overlay-backdrop-showing{opacity:.6}}
.cdk-overlay-dark-backdrop{background:rgba(0,0,0,.288)}
.cdk-overlay-transparent-backdrop,.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing{opacity:0}
.cdk-overlay-connected-position-bounding-box{position:absolute;z-index:1000;display:flex;flex-direction:column;min-width:1px;min-height:1px}
.cdk-global-scrollblock{position:fixed;width:100%;overflow-y:scroll}
.owl-dialog-container{position:relative;pointer-events:auto;box-sizing:border-box;display:block;padding:1.5em;box-shadow:0 11px 15px -7px rgba(0,0,0,.2),0 24px 38px 3px rgba(0,0,0,.14),0 9px 46px 8px rgba(0,0,0,.12);border-radius:2px;overflow:auto;background:#fff;color:rgba(0,0,0,.87);width:100%;height:100%;outline:0}
.owl-dt-container,.owl-dt-container *{box-sizing:border-box}
.owl-dt-container{display:block;font-size:16px;font-size:1rem;background:#fff;pointer-events:auto;z-index:1000}
.owl-dt-container-row{border-bottom:1px solid rgba(0,0,0,.12)}
.owl-dt-container-row:last-child{border-bottom:none}
.owl-dt-calendar{display:flex;flex-direction:column;width:100%}
.owl-dt-calendar-control{display:flex;align-items:center;font-size:1em;width:100%;padding:.5em;color:#000}
.owl-dt-calendar-control .owl-dt-calendar-control-content{flex:1 1 auto;display:flex;justify-content:center;align-items:center}
.owl-dt-calendar-control .owl-dt-calendar-control-content .owl-dt-calendar-control-button{padding:0 .8em}
.owl-dt-calendar-control .owl-dt-calendar-control-content .owl-dt-calendar-control-button:hover{background-color:rgba(0,0,0,.12)}
.owl-dt-calendar-main{display:flex;flex-direction:column;flex:1 1 auto;padding:0 .5em .5em;outline:0}
.owl-dt-calendar-view{display:block;flex:1 1 auto}
.owl-dt-calendar-multi-year-view{display:flex;align-items:center}
.owl-dt-calendar-multi-year-view .owl-dt-calendar-table{width:calc(100% - 3em)}
.owl-dt-calendar-multi-year-view .owl-dt-calendar-table .owl-dt-calendar-header th{padding-bottom:.25em}
.owl-dt-calendar-table{width:100%;border-collapse:collapse;border-spacing:0}
.owl-dt-calendar-table .owl-dt-calendar-header{color:rgba(0,0,0,.4)}
.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-weekdays th{font-size:.7em;font-weight:400;text-align:center;padding-bottom:1em}
.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-calendar-table-divider{position:relative;height:1px;padding-bottom:.5em}
.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-calendar-table-divider:after{content:'';position:absolute;top:0;left:-.5em;right:-.5em;height:1px;background:rgba(0,0,0,.12)}
.owl-dt-calendar-table .owl-dt-calendar-cell{position:relative;height:0;line-height:0;text-align:center;outline:0;color:rgba(0,0,0,.85);-webkit-appearance:none;-webkit-tap-highlight-color:transparent;-webkit-tap-highlight-color:transparent}
.owl-dt-calendar-table .owl-dt-calendar-cell-content{position:absolute;top:5%;left:5%;display:flex;align-items:center;justify-content:center;box-sizing:border-box;width:90%;height:90%;font-size:.8em;line-height:1;border:1px solid transparent;border-radius:999px;color:inherit;cursor:pointer}
.owl-dt-calendar-table .owl-dt-calendar-cell-out{opacity:.2}
.owl-dt-calendar-table .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected){border-color:rgba(0,0,0,.4)}
.owl-dt-calendar-table .owl-dt-calendar-cell-selected{color:rgba(255,255,255,.85);background-color:#3f51b5}
.owl-dt-calendar-table .owl-dt-calendar-cell-selected.owl-dt-calendar-cell-today{box-shadow:inset 0 0 0 1px rgba(255,255,255,.85)}
.owl-dt-calendar-table .owl-dt-calendar-cell-disabled{cursor:default}
.owl-dt-calendar-table .owl-dt-calendar-cell-disabled>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected){color:rgba(0,0,0,.4)}
.owl-dt-calendar-table .owl-dt-calendar-cell-disabled>.owl-dt-calendar-cell-content.owl-dt-calendar-cell-selected{opacity:.4}
.owl-dt-calendar-table .owl-dt-calendar-cell-disabled>.owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected){border-color:rgba(0,0,0,.2)}
.owl-dt-calendar-table .owl-dt-calendar-cell-active:focus>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected),.owl-dt-calendar-table :not(.owl-dt-calendar-cell-disabled):hover>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected){background-color:rgba(0,0,0,.04)}
.owl-dt-calendar-table .owl-dt-calendar-cell-in-range{background:rgba(63,81,181,.2)}
.owl-dt-calendar-table .owl-dt-calendar-cell-in-range.owl-dt-calendar-cell-range-from{border-top-left-radius:999px;border-bottom-left-radius:999px}
.owl-dt-calendar-table .owl-dt-calendar-cell-in-range.owl-dt-calendar-cell-range-to{border-top-right-radius:999px;border-bottom-right-radius:999px}
.owl-dt-timer{display:flex;justify-content:center;width:100%;height:7em;padding:.5em;outline:0}
.owl-dt-timer-box{position:relative;display:inline-flex;flex-direction:column;align-items:center;width:25%;height:100%}
.owl-dt-timer-content{flex:1 1 auto;display:flex;justify-content:center;align-items:center;width:100%;margin:.2em 0}
.owl-dt-timer-content .owl-dt-timer-input{display:block;width:2em;text-align:center;border:1px solid rgba(0,0,0,.5);border-radius:3px;outline:medium none;font-size:1.2em;padding:.2em}
.owl-dt-timer-divider{display:inline-block;align-self:flex-end;position:absolute;width:.6em;height:100%;left:-.3em}
.owl-dt-timer-divider:after,.owl-dt-timer-divider:before{content:'';display:inline-block;width:.35em;height:.35em;position:absolute;left:50%;border-radius:50%;transform:translateX(-50%);background-color:currentColor}
.owl-dt-timer-divider:before{top:35%}
.owl-dt-timer-divider:after{bottom:35%}
.owl-dt-control-button{-webkit-user-select:none;user-select:none;cursor:pointer;outline:0;border:none;-webkit-tap-highlight-color:transparent;display:inline-block;white-space:nowrap;text-decoration:none;vertical-align:baseline;margin:0;padding:0;background-color:transparent;font-size:1em;color:inherit}
.owl-dt-control-button .owl-dt-control-button-content{position:relative;display:inline-flex;justify-content:center;align-items:center;outline:0}
.owl-dt-control-period-button .owl-dt-control-button-content{height:1.5em;padding:0 .5em;border-radius:3px;transition:background-color .1s linear}
.owl-dt-control-period-button:hover>.owl-dt-control-button-content{background-color:rgba(0,0,0,.12)}
.owl-dt-control-period-button .owl-dt-control-button-arrow{display:flex;justify-content:center;align-items:center;width:1em;height:1em;margin:.1em;transition:transform .2s ease}
.owl-dt-control-arrow-button .owl-dt-control-button-content{padding:0;border-radius:50%;width:1.5em;height:1.5em}
.owl-dt-control-arrow-button[disabled]{color:rgba(0,0,0,.4);cursor:default}
.owl-dt-control-arrow-button svg{width:50%;height:50%;fill:currentColor}
.owl-dt-inline-container,.owl-dt-popup-container{position:relative;width:18.5em;box-shadow:0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.14),0 3px 14px 2px rgba(0,0,0,.12)}
.owl-dt-inline-container .owl-dt-calendar,.owl-dt-inline-container .owl-dt-timer,.owl-dt-popup-container .owl-dt-calendar,.owl-dt-popup-container .owl-dt-timer{width:100%}
.owl-dt-inline-container .owl-dt-calendar,.owl-dt-popup-container .owl-dt-calendar{height:20.25em}
.owl-dt-dialog-container{max-height:95vh;margin:-1.5em}
.owl-dt-dialog-container .owl-dt-calendar{min-width:250px;min-height:330px;max-width:750px;max-height:750px}
.owl-dt-dialog-container .owl-dt-timer{min-width:250px;max-width:750px}
@media all and (orientation:landscape){.owl-dt-dialog-container .owl-dt-calendar{width:58vh;height:62vh}.owl-dt-dialog-container .owl-dt-timer{width:58vh}}
@media all and (orientation:portrait){.owl-dt-dialog-container .owl-dt-calendar{width:80vw;height:80vw}.owl-dt-dialog-container .owl-dt-timer{width:80vw}}
.owl-dt-container-buttons{display:flex;width:100%;height:2em;color:#3f51b5}
.owl-dt-container-control-button{font-size:1em;width:50%;height:100%;border-radius:0}
.owl-dt-container-control-button .owl-dt-control-button-content{height:100%;width:100%;transition:background-color .1s linear}
.owl-dt-container-control-button:hover .owl-dt-control-button-content{background-color:rgba(0,0,0,.1)}
.owl-dt-container-info{padding:0 .5em;cursor:pointer;-webkit-tap-highlight-color:transparent}
.owl-dt-container-info .owl-dt-container-range{outline:0}
.owl-dt-container-info .owl-dt-container-range .owl-dt-container-range-content{display:flex;justify-content:space-between;padding:.5em 0;font-size:.8em}
.owl-dt-container-info .owl-dt-container-range:last-child{border-top:1px solid rgba(0,0,0,.12)}
.owl-dt-container-info .owl-dt-container-info-active{color:#3f51b5}
.owl-dt-container-disabled,.owl-dt-trigger-disabled{opacity:.35;filter:Alpha(Opacity=35);background-image:none;cursor:default!important}
.owl-dt-timer-hour12{display:flex;justify-content:center;align-items:center;color:#3f51b5}
.owl-dt-timer-hour12 .owl-dt-timer-hour12-box{border:1px solid currentColor;border-radius:2px;transition:background .2s ease}
.owl-dt-timer-hour12 .owl-dt-timer-hour12-box .owl-dt-control-button-content{width:100%;height:100%;padding:.5em}
.owl-dt-timer-hour12 .owl-dt-timer-hour12-box:focus .owl-dt-control-button-content,.owl-dt-timer-hour12 .owl-dt-timer-hour12-box:hover .owl-dt-control-button-content{background:#3f51b5;color:#fff}
.owl-dt-calendar-only-current-month .owl-dt-calendar-cell-out{visibility:hidden;cursor:default}
.owl-dt-inline{display:inline-block}
.owl-dt-control{outline:0;cursor:pointer}
.owl-dt-control .owl-dt-control-content{outline:0}
.owl-dt-control:focus>.owl-dt-control-content{background-color:rgba(0,0,0,.12)}
.owl-dt-control:not(:-moz-focusring):focus>.owl-dt-control-content{box-shadow:none}
.owl-hidden-accessible{border:0;clip:rect(0 0 0 0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}
.swal2-popup.swal2-toast {
  flex-direction: row;
  align-items: center;
  width: auto;
  padding: 0.625em;
  overflow-y: hidden;
  background: #fff;
  box-shadow: 0 0 0.625em #d9d9d9;
}
.swal2-popup.swal2-toast .swal2-header {
  flex-direction: row;
  padding: 0;
}
.swal2-popup.swal2-toast .swal2-title {
  flex-grow: 1;
  justify-content: flex-start;
  margin: 0 0.6em;
  font-size: 1em;
}
.swal2-popup.swal2-toast .swal2-footer {
  margin: 0.5em 0 0;
  padding: 0.5em 0 0;
  font-size: 0.8em;
}
.swal2-popup.swal2-toast .swal2-close {
  position: static;
  width: 0.8em;
  height: 0.8em;
  line-height: 0.8;
}
.swal2-popup.swal2-toast .swal2-content {
  justify-content: flex-start;
  padding: 0;
  font-size: 1em;
}
.swal2-popup.swal2-toast .swal2-icon {
  width: 2em;
  min-width: 2em;
  height: 2em;
  margin: 0;
}
.swal2-popup.swal2-toast .swal2-icon .swal2-icon-content {
  display: flex;
  align-items: center;
  font-size: 1.8em;
  font-weight: bold;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-popup.swal2-toast .swal2-icon .swal2-icon-content {
    font-size: 0.25em;
  }
}
.swal2-popup.swal2-toast .swal2-icon.swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em;
}
.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  top: 0.875em;
  width: 1.375em;
}
.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
  left: 0.3125em;
}
.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
  right: 0.3125em;
}
.swal2-popup.swal2-toast .swal2-actions {
  flex-basis: auto !important;
  width: auto;
  height: auto;
  margin: 0 0.3125em;
  padding: 0;
}
.swal2-popup.swal2-toast .swal2-styled {
  margin: 0.125em 0.3125em;
  padding: 0.3125em 0.625em;
  font-size: 1em;
}
.swal2-popup.swal2-toast .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px rgba(100, 150, 200, 0.5);
}
.swal2-popup.swal2-toast .swal2-success {
  border-color: #a5dc86;
}
.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line] {
  position: absolute;
  width: 1.6em;
  height: 3em;
  transform: rotate(45deg);
  border-radius: 50%;
}
.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line][class$=left] {
  top: -0.8em;
  left: -0.5em;
  transform: rotate(-45deg);
  transform-origin: 2em 2em;
  border-radius: 4em 0 0 4em;
}
.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line][class$=right] {
  top: -0.25em;
  left: 0.9375em;
  transform-origin: 0 1.5em;
  border-radius: 0 4em 4em 0;
}
.swal2-popup.swal2-toast .swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em;
}
.swal2-popup.swal2-toast .swal2-success .swal2-success-fix {
  top: 0;
  left: 0.4375em;
  width: 0.4375em;
  height: 2.6875em;
}
.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line] {
  height: 0.3125em;
}
.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line][class$=tip] {
  top: 1.125em;
  left: 0.1875em;
  width: 0.75em;
}
.swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line][class$=long] {
  top: 0.9375em;
  right: 0.1875em;
  width: 1.375em;
}
.swal2-popup.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-tip {
  animation: swal2-toast-animate-success-line-tip 0.75s;
}
.swal2-popup.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-long {
  animation: swal2-toast-animate-success-line-long 0.75s;
}
.swal2-popup.swal2-toast.swal2-show {
  animation: swal2-toast-show 0.5s;
}
.swal2-popup.swal2-toast.swal2-hide {
  animation: swal2-toast-hide 0.1s forwards;
}
.swal2-container {
  display: flex;
  position: fixed;
  z-index: 1060;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.625em;
  overflow-x: hidden;
  transition: background-color 0.1s;
  -webkit-overflow-scrolling: touch;
}
.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.4);
}
.swal2-container.swal2-backdrop-hide {
  background: transparent !important;
}
.swal2-container.swal2-top {
  align-items: flex-start;
}
.swal2-container.swal2-top-start, .swal2-container.swal2-top-left {
  align-items: flex-start;
  justify-content: flex-start;
}
.swal2-container.swal2-top-end, .swal2-container.swal2-top-right {
  align-items: flex-start;
  justify-content: flex-end;
}
.swal2-container.swal2-center {
  align-items: center;
}
.swal2-container.swal2-center-start, .swal2-container.swal2-center-left {
  align-items: center;
  justify-content: flex-start;
}
.swal2-container.swal2-center-end, .swal2-container.swal2-center-right {
  align-items: center;
  justify-content: flex-end;
}
.swal2-container.swal2-bottom {
  align-items: flex-end;
}
.swal2-container.swal2-bottom-start, .swal2-container.swal2-bottom-left {
  align-items: flex-end;
  justify-content: flex-start;
}
.swal2-container.swal2-bottom-end, .swal2-container.swal2-bottom-right {
  align-items: flex-end;
  justify-content: flex-end;
}
.swal2-container.swal2-bottom > :first-child, .swal2-container.swal2-bottom-start > :first-child, .swal2-container.swal2-bottom-left > :first-child, .swal2-container.swal2-bottom-end > :first-child, .swal2-container.swal2-bottom-right > :first-child {
  margin-top: auto;
}
.swal2-container.swal2-grow-fullscreen > .swal2-modal {
  display: flex !important;
  flex: 1;
  align-self: stretch;
  justify-content: center;
}
.swal2-container.swal2-grow-row > .swal2-modal {
  display: flex !important;
  flex: 1;
  align-content: center;
  justify-content: center;
}
.swal2-container.swal2-grow-column {
  flex: 1;
  flex-direction: column;
}
.swal2-container.swal2-grow-column.swal2-top, .swal2-container.swal2-grow-column.swal2-center, .swal2-container.swal2-grow-column.swal2-bottom {
  align-items: center;
}
.swal2-container.swal2-grow-column.swal2-top-start, .swal2-container.swal2-grow-column.swal2-center-start, .swal2-container.swal2-grow-column.swal2-bottom-start, .swal2-container.swal2-grow-column.swal2-top-left, .swal2-container.swal2-grow-column.swal2-center-left, .swal2-container.swal2-grow-column.swal2-bottom-left {
  align-items: flex-start;
}
.swal2-container.swal2-grow-column.swal2-top-end, .swal2-container.swal2-grow-column.swal2-center-end, .swal2-container.swal2-grow-column.swal2-bottom-end, .swal2-container.swal2-grow-column.swal2-top-right, .swal2-container.swal2-grow-column.swal2-center-right, .swal2-container.swal2-grow-column.swal2-bottom-right {
  align-items: flex-end;
}
.swal2-container.swal2-grow-column > .swal2-modal {
  display: flex !important;
  flex: 1;
  align-content: center;
  justify-content: center;
}
.swal2-container.swal2-no-transition {
  transition: none !important;
}
.swal2-container:not(.swal2-top):not(.swal2-top-start):not(.swal2-top-end):not(.swal2-top-left):not(.swal2-top-right):not(.swal2-center-start):not(.swal2-center-end):not(.swal2-center-left):not(.swal2-center-right):not(.swal2-bottom):not(.swal2-bottom-start):not(.swal2-bottom-end):not(.swal2-bottom-left):not(.swal2-bottom-right):not(.swal2-grow-fullscreen) > .swal2-modal {
  margin: auto;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-container .swal2-modal {
    margin: 0 !important;
  }
}
.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  width: 32em;
  max-width: 100%;
  padding: 1.25em;
  border: none;
  border-radius: 5px;
  background: #fff;
  font-family: inherit;
  font-size: 1rem;
}
.swal2-popup:focus {
  outline: none;
}
.swal2-popup.swal2-loading {
  overflow-y: hidden;
}
.swal2-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.8em;
}
.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0 0 0.4em;
  padding: 0;
  color: #595959;
  font-size: 1.875em;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
}
.swal2-actions {
  display: flex;
  z-index: 1;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 1.25em auto 0;
  padding: 0 1.6em;
}
.swal2-actions:not(.swal2-loading) .swal2-styled[disabled] {
  opacity: 0.4;
}
.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
}
.swal2-actions:not(.swal2-loading) .swal2-styled:active {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}
.swal2-loader {
  display: none;
  align-items: center;
  justify-content: center;
  width: 2.2em;
  height: 2.2em;
  margin: 0 1.875em;
  animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
  border-width: 0.25em;
  border-style: solid;
  border-radius: 100%;
  border-color: #2778c4 transparent #2778c4 transparent;
}
.swal2-styled {
  margin: 0.3125em;
  padding: 0.625em 1.1em;
  box-shadow: none;
  font-weight: 500;
}
.swal2-styled:not([disabled]) {
  cursor: pointer;
}
.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #2778c4;
  color: #fff;
  font-size: 1.0625em;
}
.swal2-styled.swal2-deny {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #d14529;
  color: #fff;
  font-size: 1.0625em;
}
.swal2-styled.swal2-cancel {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #757575;
  color: #fff;
  font-size: 1.0625em;
}
.swal2-styled:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(100, 150, 200, 0.5);
}
.swal2-styled::-moz-focus-inner {
  border: 0;
}
.swal2-footer {
  justify-content: center;
  margin: 1.25em 0 0;
  padding: 1em 0 0;
  border-top: 1px solid #eee;
  color: #545454;
  font-size: 1em;
}
.swal2-timer-progress-bar-container {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 0.25em;
  overflow: hidden;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.swal2-timer-progress-bar {
  width: 100%;
  height: 0.25em;
  background: rgba(0, 0, 0, 0.2);
}
.swal2-image {
  max-width: 100%;
  margin: 1.25em auto;
}
.swal2-close {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  width: 1.2em;
  height: 1.2em;
  padding: 0;
  overflow: hidden;
  transition: color 0.1s ease-out;
  border: none;
  border-radius: 5px;
  background: transparent;
  color: #cccccc;
  font-family: serif;
  font-size: 2.5em;
  line-height: 1.2;
  cursor: pointer;
}
.swal2-close:hover {
  transform: none;
  background: transparent;
  color: #f27474;
}
.swal2-close:focus {
  outline: none;
  box-shadow: inset 0 0 0 3px rgba(100, 150, 200, 0.5);
}
.swal2-close::-moz-focus-inner {
  border: 0;
}
.swal2-content {
  z-index: 1;
  justify-content: center;
  margin: 0;
  padding: 0 1.6em;
  color: #545454;
  font-size: 1.125em;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
}
.swal2-input,
.swal2-file,
.swal2-textarea,
.swal2-select,
.swal2-radio,
.swal2-checkbox {
  margin: 1em auto;
}
.swal2-input,
.swal2-file,
.swal2-textarea {
  box-sizing: border-box;
  width: 100%;
  transition: border-color 0.3s, box-shadow 0.3s;
  border: 1px solid #d9d9d9;
  border-radius: 0.1875em;
  background: inherit;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06);
  color: inherit;
  font-size: 1.125em;
}
.swal2-input.swal2-inputerror,
.swal2-file.swal2-inputerror,
.swal2-textarea.swal2-inputerror {
  border-color: #f27474 !important;
  box-shadow: 0 0 2px #f27474 !important;
}
.swal2-input:focus,
.swal2-file:focus,
.swal2-textarea:focus {
  border: 1px solid #b4dbed;
  outline: none;
  box-shadow: 0 0 0 3px rgba(100, 150, 200, 0.5);
}
.swal2-input::placeholder,
.swal2-file::placeholder,
.swal2-textarea::placeholder {
  color: #cccccc;
}
.swal2-range {
  margin: 1em auto;
  background: #fff;
}
.swal2-range input {
  width: 80%;
}
.swal2-range output {
  width: 20%;
  color: inherit;
  font-weight: 600;
  text-align: center;
}
.swal2-range input,
.swal2-range output {
  height: 2.625em;
  padding: 0;
  font-size: 1.125em;
  line-height: 2.625em;
}
.swal2-input {
  height: 2.625em;
  padding: 0 0.75em;
}
.swal2-input[type=number] {
  max-width: 10em;
}
.swal2-file {
  background: inherit;
  font-size: 1.125em;
}
.swal2-textarea {
  height: 6.75em;
  padding: 0.75em;
}
.swal2-select {
  min-width: 50%;
  max-width: 100%;
  padding: 0.375em 0.625em;
  background: inherit;
  color: inherit;
  font-size: 1.125em;
}
.swal2-radio,
.swal2-checkbox {
  align-items: center;
  justify-content: center;
  background: #fff;
  color: inherit;
}
.swal2-radio label,
.swal2-checkbox label {
  margin: 0 0.6em;
  font-size: 1.125em;
}
.swal2-radio input,
.swal2-checkbox input {
  margin: 0 0.4em;
}
.swal2-input-label {
  display: flex;
  justify-content: center;
  margin: 1em auto;
}
.swal2-validation-message {
  display: none;
  align-items: center;
  justify-content: center;
  margin: 0 -2.7em;
  padding: 0.625em;
  overflow: hidden;
  background: #f0f0f0;
  color: #666666;
  font-size: 1em;
  font-weight: 300;
}
.swal2-validation-message::before {
  content: "!";
  display: inline-block;
  width: 1.5em;
  min-width: 1.5em;
  height: 1.5em;
  margin: 0 0.625em;
  border-radius: 50%;
  background-color: #f27474;
  color: #fff;
  font-weight: 600;
  line-height: 1.5em;
  text-align: center;
}
.swal2-icon {
  position: relative;
  box-sizing: content-box;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 1.25em auto 1.875em;
  border: 0.25em solid transparent;
  border-radius: 50%;
  border-color: #000;
  font-family: inherit;
  line-height: 5em;
  cursor: default;
  -webkit-user-select: none;
          user-select: none;
}
.swal2-icon .swal2-icon-content {
  display: flex;
  align-items: center;
  font-size: 3.75em;
}
.swal2-icon.swal2-error {
  border-color: #f27474;
  color: #f27474;
}
.swal2-icon.swal2-error .swal2-x-mark {
  position: relative;
  flex-grow: 1;
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  display: block;
  position: absolute;
  top: 2.3125em;
  width: 2.9375em;
  height: 0.3125em;
  border-radius: 0.125em;
  background-color: #f27474;
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
  left: 1.0625em;
  transform: rotate(45deg);
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
  right: 1em;
  transform: rotate(-45deg);
}
.swal2-icon.swal2-error.swal2-icon-show {
  animation: swal2-animate-error-icon 0.5s;
}
.swal2-icon.swal2-error.swal2-icon-show .swal2-x-mark {
  animation: swal2-animate-error-x-mark 0.5s;
}
.swal2-icon.swal2-warning {
  border-color: #facea8;
  color: #f8bb86;
}
.swal2-icon.swal2-info {
  border-color: #9de0f6;
  color: #3fc3ee;
}
.swal2-icon.swal2-question {
  border-color: #c9dae1;
  color: #87adbd;
}
.swal2-icon.swal2-success {
  border-color: #a5dc86;
  color: #a5dc86;
}
.swal2-icon.swal2-success [class^=swal2-success-circular-line] {
  position: absolute;
  width: 3.75em;
  height: 7.5em;
  transform: rotate(45deg);
  border-radius: 50%;
}
.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=left] {
  top: -0.4375em;
  left: -2.0635em;
  transform: rotate(-45deg);
  transform-origin: 3.75em 3.75em;
  border-radius: 7.5em 0 0 7.5em;
}
.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=right] {
  top: -0.6875em;
  left: 1.875em;
  transform: rotate(-45deg);
  transform-origin: 0 3.75em;
  border-radius: 0 7.5em 7.5em 0;
}
.swal2-icon.swal2-success .swal2-success-ring {
  position: absolute;
  z-index: 2;
  top: -0.25em;
  left: -0.25em;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  border: 0.25em solid rgba(165, 220, 134, 0.3);
  border-radius: 50%;
}
.swal2-icon.swal2-success .swal2-success-fix {
  position: absolute;
  z-index: 1;
  top: 0.5em;
  left: 1.625em;
  width: 0.4375em;
  height: 5.625em;
  transform: rotate(-45deg);
}
.swal2-icon.swal2-success [class^=swal2-success-line] {
  display: block;
  position: absolute;
  z-index: 2;
  height: 0.3125em;
  border-radius: 0.125em;
  background-color: #a5dc86;
}
.swal2-icon.swal2-success [class^=swal2-success-line][class$=tip] {
  top: 2.875em;
  left: 0.8125em;
  width: 1.5625em;
  transform: rotate(45deg);
}
.swal2-icon.swal2-success [class^=swal2-success-line][class$=long] {
  top: 2.375em;
  right: 0.5em;
  width: 2.9375em;
  transform: rotate(-45deg);
}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-tip {
  animation: swal2-animate-success-line-tip 0.75s;
}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-long {
  animation: swal2-animate-success-line-long 0.75s;
}
.swal2-icon.swal2-success.swal2-icon-show .swal2-success-circular-line-right {
  animation: swal2-rotate-success-circular-line 4.25s ease-in;
}
.swal2-progress-steps {
  flex-wrap: wrap;
  align-items: center;
  max-width: 100%;
  margin: 0 0 1.25em;
  padding: 0;
  background: inherit;
  font-weight: 600;
}
.swal2-progress-steps li {
  display: inline-block;
  position: relative;
}
.swal2-progress-steps .swal2-progress-step {
  z-index: 20;
  flex-shrink: 0;
  width: 2em;
  height: 2em;
  border-radius: 2em;
  background: #2778c4;
  color: #fff;
  line-height: 2em;
  text-align: center;
}
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
  background: #2778c4;
}
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step {
  background: #add8e6;
  color: #fff;
}
.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step-line {
  background: #add8e6;
}
.swal2-progress-steps .swal2-progress-step-line {
  z-index: 10;
  flex-shrink: 0;
  width: 2.5em;
  height: 0.4em;
  margin: 0 -1px;
  background: #2778c4;
}
[class^=swal2] {
  -webkit-tap-highlight-color: transparent;
}
.swal2-show {
  animation: swal2-show 0.3s;
}
.swal2-hide {
  animation: swal2-hide 0.15s forwards;
}
.swal2-noanimation {
  transition: none;
}
.swal2-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
.swal2-rtl .swal2-close {
  right: auto;
  left: 0;
}
.swal2-rtl .swal2-timer-progress-bar {
  right: 0;
  left: auto;
}
@supports (-ms-accelerator: true) {
  .swal2-range input {
    width: 100% !important;
  }
  .swal2-range output {
    display: none;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-range input {
    width: 100% !important;
  }
  .swal2-range output {
    display: none;
  }
}
@keyframes swal2-toast-show {
  0% {
    transform: translateY(-0.625em) rotateZ(2deg);
  }
  33% {
    transform: translateY(0) rotateZ(-2deg);
  }
  66% {
    transform: translateY(0.3125em) rotateZ(2deg);
  }
  100% {
    transform: translateY(0) rotateZ(0deg);
  }
}
@keyframes swal2-toast-hide {
  100% {
    transform: rotateZ(1deg);
    opacity: 0;
  }
}
@keyframes swal2-toast-animate-success-line-tip {
  0% {
    top: 0.5625em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 0.125em;
    left: 0.125em;
    width: 0;
  }
  70% {
    top: 0.625em;
    left: -0.25em;
    width: 1.625em;
  }
  84% {
    top: 1.0625em;
    left: 0.75em;
    width: 0.5em;
  }
  100% {
    top: 1.125em;
    left: 0.1875em;
    width: 0.75em;
  }
}
@keyframes swal2-toast-animate-success-line-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0;
  }
  65% {
    top: 1.25em;
    right: 0.9375em;
    width: 0;
  }
  84% {
    top: 0.9375em;
    right: 0;
    width: 1.125em;
  }
  100% {
    top: 0.9375em;
    right: 0.1875em;
    width: 1.375em;
  }
}
@keyframes swal2-show {
  0% {
    transform: scale(0.7);
  }
  45% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes swal2-hide {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
}
@keyframes swal2-animate-success-line-tip {
  0% {
    top: 1.1875em;
    left: 0.0625em;
    width: 0;
  }
  54% {
    top: 1.0625em;
    left: 0.125em;
    width: 0;
  }
  70% {
    top: 2.1875em;
    left: -0.375em;
    width: 3.125em;
  }
  84% {
    top: 3em;
    left: 1.3125em;
    width: 1.0625em;
  }
  100% {
    top: 2.8125em;
    left: 0.8125em;
    width: 1.5625em;
  }
}
@keyframes swal2-animate-success-line-long {
  0% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  65% {
    top: 3.375em;
    right: 2.875em;
    width: 0;
  }
  84% {
    top: 2.1875em;
    right: 0;
    width: 3.4375em;
  }
  100% {
    top: 2.375em;
    right: 0.5em;
    width: 2.9375em;
  }
}
@keyframes swal2-rotate-success-circular-line {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}
@keyframes swal2-animate-error-x-mark {
  0% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0;
  }
  50% {
    margin-top: 1.625em;
    transform: scale(0.4);
    opacity: 0;
  }
  80% {
    margin-top: -0.375em;
    transform: scale(1.15);
  }
  100% {
    margin-top: 0;
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes swal2-animate-error-icon {
  0% {
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}
@keyframes swal2-rotate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow: hidden;
}
body.swal2-height-auto {
  height: auto !important;
}
body.swal2-no-backdrop .swal2-container {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  max-width: calc(100% - 0.625em * 2);
  background-color: transparent !important;
}
body.swal2-no-backdrop .swal2-container > .swal2-modal {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}
body.swal2-no-backdrop .swal2-container.swal2-top {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
body.swal2-no-backdrop .swal2-container.swal2-top-start, body.swal2-no-backdrop .swal2-container.swal2-top-left {
  top: 0;
  left: 0;
}
body.swal2-no-backdrop .swal2-container.swal2-top-end, body.swal2-no-backdrop .swal2-container.swal2-top-right {
  top: 0;
  right: 0;
}
body.swal2-no-backdrop .swal2-container.swal2-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
body.swal2-no-backdrop .swal2-container.swal2-center-start, body.swal2-no-backdrop .swal2-container.swal2-center-left {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
body.swal2-no-backdrop .swal2-container.swal2-center-end, body.swal2-no-backdrop .swal2-container.swal2-center-right {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
body.swal2-no-backdrop .swal2-container.swal2-bottom {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
body.swal2-no-backdrop .swal2-container.swal2-bottom-start, body.swal2-no-backdrop .swal2-container.swal2-bottom-left {
  bottom: 0;
  left: 0;
}
body.swal2-no-backdrop .swal2-container.swal2-bottom-end, body.swal2-no-backdrop .swal2-container.swal2-bottom-right {
  right: 0;
  bottom: 0;
}
@media print {
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    overflow-y: scroll !important;
  }
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) > [aria-hidden=true] {
    display: none;
  }
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) .swal2-container {
    position: static !important;
  }
}
body.swal2-toast-shown .swal2-container {
  background-color: transparent;
}
body.swal2-toast-shown .swal2-container.swal2-top {
  top: 0;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-top-end, body.swal2-toast-shown .swal2-container.swal2-top-right {
  top: 0;
  right: 0;
  bottom: auto;
  left: auto;
}
body.swal2-toast-shown .swal2-container.swal2-top-start, body.swal2-toast-shown .swal2-container.swal2-top-left {
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
}
body.swal2-toast-shown .swal2-container.swal2-center-start, body.swal2-toast-shown .swal2-container.swal2-center-left {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 0;
  transform: translateY(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-center {
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translate(-50%, -50%);
}
body.swal2-toast-shown .swal2-container.swal2-center-end, body.swal2-toast-shown .swal2-container.swal2-center-right {
  top: 50%;
  right: 0;
  bottom: auto;
  left: auto;
  transform: translateY(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-bottom-start, body.swal2-toast-shown .swal2-container.swal2-bottom-left {
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
}
body.swal2-toast-shown .swal2-container.swal2-bottom {
  top: auto;
  right: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
body.swal2-toast-shown .swal2-container.swal2-bottom-end, body.swal2-toast-shown .swal2-container.swal2-bottom-right {
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
}
body.swal2-toast-column .swal2-toast {
  flex-direction: column;
  align-items: stretch;
}
body.swal2-toast-column .swal2-toast .swal2-actions {
  flex: 1;
  align-self: stretch;
  height: 2.2em;
  margin-top: 0.3125em;
}
body.swal2-toast-column .swal2-toast .swal2-loading {
  justify-content: center;
}
body.swal2-toast-column .swal2-toast .swal2-input {
  height: 2em;
  margin: 0.3125em auto;
  font-size: 1em;
}
body.swal2-toast-column .swal2-toast .swal2-validation-message {
  font-size: 1em;
}
app-student-calendar.ng-star-inserted {
  width: 100%;
}
full-calendar {
  height: 97vh;
}
.fc .fc-toolbar {
  padding: 10px 20px 0 20px;
  margin: 0 !important;
}
td .fc-daygrid-day.fc-day.fc-day-sun.fc-day-future.fc-day-other:nth-child(1) {
  width: unset;
}
td .fc-daygrid-day.fc-day.fc-day-sun.fc-day-past.fc-day-other:nth-child(1) {
  width: unset;
}
td .fc-daygrid-day.fc-day.fc-day-sun.fc-day-future:nth-child(1) {
  width: unset;
}
td .fc-daygrid-day.fc-day.fc-day-sun.fc-day-past:nth-child(1) {
  width: unset;
}
.student-calendar {
  margin-top: 60px;
}
.fc-scrollgrid-sync-table {
  min-width: 100%;
  height: 85vh !important;
  margin-top: -3px;
}
.fc-col-header .fc-scrollgrid-sync-inner {
  background-color: #00bbff;
  color: #fff;
  padding: 5px 0;
  border-radius: 4px;
}
.fc-scroller {
  overflow: unset !important;
}
.fc .fc-scrollgrid-liquid {
  border: none !important;
}
.fc-col-header {
  width: 100% !important;
  margin: 0;
}
.fc-scroller.fc-scroller-liquid-absolute {
  overflow: unset !important;
}
.fc-daygrid-body.fc-daygrid-body-unbalanced {
  width: 100% !important;
}
.fc-event-title.fc-sticky {
  white-space: break-spaces !important;
}
* {
  font-family: montserrat;
}
body {
  padding-right: 0 !important;
}
.addEllipsis {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 300px;
  cursor: default;
}
.editButton {
  color: #00bbff;
  font-weight: bold;
  font-size: 1.3rem;
}
.rc-anchor-light {
  background: #fff !important;
}
mat-spinner {
  width: 30px !important;
  height: 30px !important;
}
mat-spinner svg {
  width: 30px !important;
  height: 30px !important;
}
.processing mat-spinner {
  width: 50px !important;
  height: 50px !important;
}
.processing mat-spinner svg {
  width: 50px !important;
  height: 50px !important;
}
.processing .mat-progress-spinner circle, .processing .mat-spinner circle {
  stroke: #fff;
}
.ngx-dropdown-container .ngx-dropdown-button {
  min-height: 30px !important;
  background-color: #fff !important;
  width: 100% !important;
  margin: 10px 0 !important;
  color: #434343 !important;
  font: normal normal normal 1.25rem/30px Montserrat !important;
  box-sizing: border-box !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 4px !important;
  outline: none !important;
  resize: none !important;
}
.ng2-pdf-viewer-container {
  scroll-behavior: smooth !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  overflow-x: hidden !important;
}
.clickable {
  color: #00bbff;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
}
.remove {
  white-space: nowrap;
  padding: 10px;
  margin-left: 10px;
  color: white;
  background: #ea3e3e;
  font: normal normal bold 1rem/24px Montserrat;
  border: unset;
  border-radius: 20px;
  cursor: pointer;
}
.paginationContainer {
  display: grid;
  grid-template-columns: 20% 60% 20%;
  align-items: center;
  text-align: center;
  padding-bottom: 30px;
}
.paginationContainer .num {
  margin: 0 !important;
  padding: 0 !important;
}
.paginationContainer .reportBtn {
  margin: 0 !important;
}
.paginationContainer .ngx-pagination {
  margin: 0;
  padding: 0;
}
.filtersContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filtersContainer select {
  border: 1px solid #b1afaf;
  border-radius: 10px;
  padding: 5px 15px;
  margin-left: 10px;
  height: 100%;
  font-size: 0.75rem;
}
.filtersContainer input {
  border: 1px solid #b1afaf;
  border-radius: 10px;
  padding: 5px 15px;
  margin-left: 10px;
  font-size: 0.75rem;
}
.filtersContainer input::placeholder {
  font-size: 0.75rem;
}
.filtersContainer .selectors {
  display: flex;
}
.filtersContainer button {
  border-radius: 10px;
  margin-left: 10px;
}
.noWrap {
  white-space: nowrap;
}
.reportBtn {
  color: #fff;
  background: #00bbff;
  border-radius: 24px;
  border: none;
  padding: 5px 15px;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
}
.zeroState {
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
}
.deleteBtn {
  background: crimson;
}
.tableWithExpandedInfo {
  box-shadow: 0px 0px 15px #00000012;
  border-radius: 20px;
  background: white;
  padding: 20px;
  margin-top: 15px;
}
.tableWithExpandedInfo > h4 {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tableWithExpandedInfo > div {
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
  box-shadow: 0px 0px 15px #00000012;
  border-radius: 20px;
  border: 1px solid #bbb;
  margin-bottom: 15px;
}
.tableWithExpandedInfo > div > div {
  display: grid;
  grid-template-columns: 20% 70% 10%;
  align-items: center;
}
.tableWithExpandedInfo > div > div h4 {
  text-align: center;
  font-size: 1rem;
  width: 70%;
  margin: auto;
}
.tableWithExpandedInfo > div > div a {
  text-align: center;
}
.tableWithExpandedInfo > div > div > div {
  padding: 0 20px;
  border-left: 2px solid #434343;
  border-right: 2px solid #434343;
}
.tableWithExpandedInfo > div > div > div > div:nth-child(1) {
  border-bottom: 2px solid #4343434d;
}
.tableWithExpandedInfo > div > div > div > div {
  padding: 10px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 20% 20% 10% 10% 10% 15% 15%;
  align-items: center;
}
.tableWithExpandedInfo > div > div > div > div span {
  text-align: center;
  font-size: 0.85rem;
}
.tableWithExpandedInfoFaculties {
  box-shadow: 0px 0px 15px #00000012;
  border-radius: 20px;
  background: white;
  padding: 20px;
  margin-top: 15px;
}
.tableWithExpandedInfoFaculties > h4 {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tableWithExpandedInfoFaculties > div {
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
  box-shadow: 0px 0px 15px #00000012;
  border-radius: 20px;
  border: 1px solid #bbb;
  margin-bottom: 15px;
}
.tableWithExpandedInfoFaculties > div > div {
  display: grid;
  grid-template-columns: 20% 70% 10%;
  align-items: center;
}
.tableWithExpandedInfoFaculties > div > div h4 {
  text-align: center;
  font-size: 1rem;
  width: 70%;
  margin: auto;
}
.tableWithExpandedInfoFaculties > div > div a {
  text-align: center;
}
.tableWithExpandedInfoFaculties > div > div > div {
  padding: 0 20px;
  border-left: 2px solid #434343;
  border-right: 2px solid #434343;
}
.tableWithExpandedInfoFaculties > div > div > div > div:nth-child(1) {
  border-bottom: 2px solid #4343434d;
}
.tableWithExpandedInfoFaculties > div > div > div > div {
  padding: 10px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 9% 9% 9% 9% 9% 9% 9% 15% 15%;
  align-items: center;
}
.tableWithExpandedInfoFaculties > div > div > div > div span {
  text-align: center;
  font-size: 0.85rem;
}
.tableWithExpandedInfoDepartments {
  box-shadow: 0px 0px 15px #00000012;
  border-radius: 20px;
  background: white;
  padding: 20px;
  margin-top: 15px;
}
.tableWithExpandedInfoDepartments > h4 {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tableWithExpandedInfoDepartments > div {
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
  box-shadow: 0px 0px 15px #00000012;
  border-radius: 20px;
  border: 1px solid #bbb;
  margin-bottom: 15px;
}
.tableWithExpandedInfoDepartments > div > div {
  display: grid;
  grid-template-columns: 20% 70% 10%;
  align-items: center;
}
.tableWithExpandedInfoDepartments > div > div h4 {
  text-align: center;
  font-size: 1rem;
  width: 70%;
  margin: auto;
}
.tableWithExpandedInfoDepartments > div > div a {
  text-align: center;
}
.tableWithExpandedInfoDepartments > div > div > div {
  padding: 0 20px;
  border-left: 2px solid #434343;
  border-right: 2px solid #434343;
}
.tableWithExpandedInfoDepartments > div > div > div > div:nth-child(1) {
  border-bottom: 2px solid #4343434d;
}
.tableWithExpandedInfoDepartments > div > div > div > div {
  padding: 10px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 11% 11% 13% 13% 13% 13% 13% 14%;
  align-items: center;
}
.tableWithExpandedInfoDepartments > div > div > div > div span {
  text-align: center;
  font-size: 0.85rem;
}
.tableWithExpandedInfoAssignments {
  box-shadow: 0px 0px 15px #00000012;
  border-radius: 20px;
  background: white;
  padding: 20px;
  margin-top: 15px;
}
.tableWithExpandedInfoAssignments > h4 {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tableWithExpandedInfoAssignments > div {
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
  box-shadow: 0px 0px 15px #00000012;
  border-radius: 20px;
  border: 1px solid #bbb;
  margin-bottom: 15px;
}
.tableWithExpandedInfoAssignments > div > div {
  display: grid;
  grid-template-columns: 20% 70% 10%;
  align-items: center;
}
.tableWithExpandedInfoAssignments > div > div h4 {
  text-align: center;
  font-size: 1rem;
  width: 70%;
  margin: auto;
}
.tableWithExpandedInfoAssignments > div > div a {
  text-align: center;
}
.tableWithExpandedInfoAssignments > div > div > div {
  padding: 0 20px;
  border-left: 2px solid #434343;
  border-right: 2px solid #434343;
}
.tableWithExpandedInfoAssignments > div > div > div > div:nth-child(1) {
  border-bottom: 2px solid #4343434d;
}
.tableWithExpandedInfoAssignments > div > div > div > div {
  padding: 10px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 13% 14%;
  align-items: center;
}
.tableWithExpandedInfoAssignments > div > div > div > div span {
  text-align: center;
  font-size: 0.85rem;
}
.tablewithRatios .moreInfo {
  display: block !important;
}
.tablewithRatios .moreInfo > .details {
  border: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.tablewithRatios .moreInfo > .details span {
  margin: 5px 0;
}
.tablewithRatios .expandedInfo {
  display: block !important;
  height: 200px !important;
}
.tablewithRatios .ratios {
  border: none;
  color: #00bbff;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 15px 0 10px 0;
}
.tableWithExpandedInfoThesis {
  box-shadow: 0px 0px 15px #00000012;
  border-radius: 20px;
  background: white;
  padding: 20px;
  margin-top: 15px;
}
.tableWithExpandedInfoThesis > h4 {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tableWithExpandedInfoThesis > div {
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
  box-shadow: 0px 0px 15px #00000012;
  border-radius: 20px;
  border: 1px solid #bbb;
  margin-bottom: 15px;
}
.tableWithExpandedInfoThesis > div > div {
  display: grid;
  grid-template-columns: 20% 70% 10%;
  align-items: center;
}
.tableWithExpandedInfoThesis > div > div h4 {
  text-align: center;
  font-size: 1rem;
  width: 70%;
  margin: auto;
}
.tableWithExpandedInfoThesis > div > div a {
  text-align: center;
}
.tableWithExpandedInfoThesis > div > div > div {
  padding: 0 20px;
  border-left: 2px solid #434343;
  border-right: 2px solid #434343;
}
.tableWithExpandedInfoThesis > div > div > div > div:nth-child(1) {
  border-bottom: 2px solid #4343434d;
}
.tableWithExpandedInfoThesis > div > div > div > div {
  padding: 10px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 12.5% 12% 12% 12% 12% 12.5% 12.5% 14.5%;
  align-items: center;
}
.tableWithExpandedInfoThesis > div > div > div > div span {
  text-align: center;
  font-size: 0.85rem;
}
.moreInfo {
  height: 0;
  overflow: hidden;
  transition: 0.3s;
  display: grid;
  grid-template-columns: repeat(4, 1fr) !important;
}
.expandedInfo {
  height: 100px;
  border-top: 2px solid #4343434d;
  padding: 10px;
  margin-top: 10px;
}
.blueText {
  color: #00bbff;
}
.underline {
  text-decoration: underline;
  cursor: pointer;
}
.bold {
  font-weight: bold;
}
.shadowBorder {
  box-shadow: 0px 0px 15px #00000012;
  border-radius: 20px;
  background: white;
}
pdf-viewer {
  /* Track */
  /* Handle */
  /* Handle on hover */
}
pdf-viewer ::-webkit-scrollbar {
  width: 12px;
  margin-right: 5px;
}
pdf-viewer ::-webkit-scrollbar-track {
  background: #e8e8e8;
  border-radius: 6px;
}
pdf-viewer ::-webkit-scrollbar-thumb {
  background: #b1b1b1;
  border-radius: 6px;
}
pdf-viewer ::-webkit-scrollbar-thumb:hover {
  background: #30697e;
}
em {
  color: red;
  font-weight: bold;
}
.content_wrapper {
  width: 100%;
  border-radius: 20px;
  border: 1px solid #d9d9d9;
  background: white;
}
.textLayer {
  border-bottom: 3px solid #717197;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 1 !important;
  line-height: 1;
}
.container {
  width: 96%;
  margin: auto;
}
.w-87 {
  width: 87%;
  height: 60px;
  margin: 0;
}
.mobile-nav {
  align-items: center;
  justify-content: space-between;
  margin: 0;
  display: none;
}
.mat-dialog-container {
  padding: 0 !important;
  border-radius: 20px !important;
}
input[type=checkbox] + label {
  display: block;
  margin: 0.2em;
  cursor: pointer;
  padding: 0.2em;
}
input[type=checkbox] {
  display: none;
}
input[type=checkbox] + label:before {
  content: "✔";
  border: 1px solid #aaa8a8;
  border-radius: 0.2em;
  display: inline-block;
  width: 1em;
  height: 1em;
  padding-left: 0.2em;
  padding-bottom: 0.3em;
  margin-right: 8px;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
}
input[type=checkbox] + label:active:before {
  transform: scale(0);
}
input[type=checkbox]:checked + label:before {
  background-color: #00bbff;
  border-color: MediumSeaGreen;
  color: #fff;
}
input[type=checkbox]:disabled + label:before {
  transform: scale(1);
  border-color: #aaa;
}
input[type=checkbox]:checked:disabled + label:before {
  transform: scale(1);
  background-color: #bfb;
  border-color: #bfb;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
body {
  background: #f1f1f4;
  margin: 0;
}
body > h4 {
  margin-left: 11%;
}
app-add-credits {
  width: 100%;
}
app-transaction {
  width: 100%;
}
app-billing-information {
  width: 100%;
}
app-settings {
  width: 100%;
}
app-personal-information {
  width: 100%;
}
app-how-to-use {
  width: 100%;
}
app-faqs {
  width: 100%;
}
app-contact-us {
  width: 100%;
}
app-upload-file {
  width: 100%;
}
app-upload-draft-file {
  width: 100%;
}
app-input-text {
  width: 100%;
}
app-web-import {
  width: 100%;
}
app-upload-form {
  width: 100%;
}
app-individual-dashboard {
  width: 100%;
}
app-individual-uploads {
  width: 100%;
}
app-individual-statistics {
  width: 100% !important;
}
app-profile {
  width: 100%;
}
app-help-center {
  width: 100%;
}
header {
  padding: 20px;
  background: white;
  margin-bottom: 36px;
}
header h5 {
  color: #707070;
}
header span {
  display: block;
}
header .logo {
  width: 17%;
}
header svg {
  margin-left: 60px;
  margin-right: 13px;
}
h5 {
  font: normal normal normal 1.25rem/24px Montserrat;
  color: #434343;
  margin: 0;
}
h4 {
  color: #434343;
  font: normal normal normal 1.56rem/1.75rem Montserrat;
}
input {
  color: #434343;
  font: normal normal normal 1.25rem/30px Montserrat;
  box-sizing: border-box;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  outline: none;
  resize: none;
}
input::placeholder {
  color: #434343;
}
input::placeholder {
  font-size: 1rem !important;
  color: rgba(0, 0, 0, 0.7) !important;
}
input:focus::placeholder {
  color: transparent;
}
textarea {
  color: #434343;
  font: normal normal normal 1.25rem/30px Montserrat;
  box-sizing: border-box;
  padding: 10px 15px;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  outline: none;
  resize: none;
}
button {
  outline: none;
}
.blue-btn {
  font-size: 1rem;
  line-height: 24px;
  color: white;
  background: #00bbff;
  cursor: pointer;
  font-weight: bold;
}
.blue-btn:hover {
  background: #4c9dbb;
  transition: all 0.3s;
}
.blue-btn:before {
  background: #2c1a1a;
  color: #00bbff;
  transition: 0.3s;
}
.blue-btn:hover:before {
  width: 100%;
}
.delete-btn {
  font-size: 1.25rem;
  line-height: 24px;
  color: white;
  background: #f34135;
  cursor: pointer;
  white-space: nowrap;
}
.delete-btn > i {
  margin-right: 5px;
}
.delete-btn:hover {
  background: #f34135;
  transition: all 0.3s;
}
.delete-btn:before {
  background: white;
  color: #f34135;
  transition: 0.3s;
}
.delete-btn:hover:before {
  width: 100%;
}
.delete {
  cursor: pointer;
}
.yellow-btn {
  padding: 14px 20px !important;
  display: inline-flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  text-align: center;
  font: normal normal 900 1.25rem/24px Montserrat !important;
  line-height: 24px;
  color: white;
  background: #ecc436;
  white-space: nowrap;
  cursor: pointer;
}
.yellow-btn > svg {
  margin-right: 5px;
  color: white;
  width: 20px;
  height: 20px;
}
.yellow-btn:hover {
  background: #ecc436;
  transition: all 0.3s;
}
.yellow-btn:before {
  background: white;
  color: #ecc436;
  transition: 0.3s;
}
.tooltip {
  position: absolute;
  color: gray;
  top: -13px;
  font-size: 1.99rem;
  left: -12px;
  display: inline-block;
  text-align: left;
  z-index: 1;
}
.tooltip > .left {
  min-width: 250px;
  max-width: 400px;
  top: 61px;
  right: 5%;
  margin-right: 20px;
  transform: translate(0, -50%);
  padding: 16px;
  color: #666666;
  background: white;
  font-weight: normal;
  font-size: 1rem;
  border-radius: 8px;
  position: absolute;
  z-index: -1;
  box-sizing: border-box;
  display: none;
  border: 1px solid #b1b5b4;
  box-shadow: 0px 0px 15px #00000024;
}
.tooltip > .left > p {
  padding-left: 6px;
}
.tooltip h3 {
  margin: 12px 0;
}
.tooltip:hover .left {
  display: block;
}
.submit {
  border-radius: 24px;
  box-shadow: 0px 0px 7px #00000012;
  padding: 8px 52px;
  outline: none;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  margin-top: 33px;
  color: white;
  background: #00bbff;
  border: 1px solid #dcdcdc;
  font: normal normal bold 1.25rem/24px Montserrat;
  box-sizing: border-box;
  cursor: pointer;
}
.submit:hover {
  background: #4c9dbb;
  transition: all 0.3s;
}
.red-border {
  border: 1px solid #f04e67;
  background: #f37d7d12;
  white-space: nowrap;
}
.red-border > span {
  border-right: 1px solid #f04e67;
}
.green-border {
  border: 1px solid #60c194;
  background: #f4fbf7;
  white-space: nowrap;
}
.green-border > span {
  border-right: 1px solid #60c194;
}
.orange-border {
  border: 1px solid #fccd29;
  background: #fffdf4;
}
.orange-border > span {
  border-right: 1px solid #fccd29;
}
.gray-border {
  border: 1px solid #9b9b9b;
  background: #fafafa;
}
.gray-border > span {
  border-right: 1px solid #9b9b9b;
}
.lowAverage {
  color: #60c194;
}
.mediumAverage {
  color: #fccd29;
}
.highAverage {
  color: #f04e67;
}
.gray-border,
.green-border,
.red-border,
.orange-border {
  border-radius: 26px;
  margin: auto;
  display: inline-block;
  display: inline-block;
  font-weight: bold;
  color: #000000;
  padding: 14px 15px;
}
.gray-border > span,
.green-border > span,
.red-border > span,
.orange-border > span {
  font: normal normal bold 1rem/19px Montserrat;
  padding: 18px 0 16px 0;
  margin-right: 12px;
  padding-right: 12px;
}
main {
  box-shadow: 0px 0px 15px #00000012;
  border-radius: 20px;
  background: white;
}
select {
  color: #434343;
  font: normal normal normal 1.25rem/30px Montserrat;
  outline: none;
  background: transparent;
}
.border {
  border: 1px solid #d9d9d9;
  border-radius: 20px;
}
.bold {
  font-weight: 900 !important;
}
.blue-background {
  color: white;
  background: #00bbff;
  border-radius: 24px;
  border: none;
}
.num {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 42px;
  margin-bottom: 20px;
}
.num li > a {
  background: #e9e9e9;
  border-radius: 50%;
  margin-right: 7px;
  margin-left: 7px;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal bold 1.43rem/28px Montserrat;
  line-height: unset;
  color: #434343;
  cursor: pointer;
  padding: 0;
  outline: none;
}
.num i {
  font-size: 1.43rem;
}
.num .ngx-pagination {
  padding-left: 0;
}
.num .ngx-pagination .current {
  background: #00bbff;
  color: white;
  padding: 0;
  border-radius: 50%;
  margin-right: 7px;
  margin-left: 7px;
  width: 48px;
  height: 48px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font: normal normal bold 1.43rem/28px Montserrat;
}
.num .ngx-pagination .pagination-next a::after,
.num .ngx-pagination .pagination-next.disabled::after {
  margin-left: 0;
  background: #e9e9e904;
  padding: 0;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font: normal normal bold 1.43rem/28px Montserrat;
}
.num .ngx-pagination .pagination-previous a::before,
.num .ngx-pagination .pagination-previous.disabled::before {
  margin-right: 0;
  background: #e9e9e904;
  padding: 0;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font: normal normal bold 1.43rem/28px Montserrat;
}
.num li > a:hover {
  background: #00bbff;
  color: white;
  transition: 0.3s;
}
.shadow {
  box-shadow: 0px 0px 15px #00000012;
  border-radius: 20px;
}
table {
  width: 100%;
  text-align: center;
  margin-bottom: 60px;
  margin-top: 34px;
  word-wrap: none;
}
table th:nth-child(1) h5 {
  margin-left: 71px;
  text-align: left;
}
table td:nth-child(1) {
  word-break: break-all;
  width: 200px;
}
table td:nth-child(1) h5 {
  margin-left: 71px;
  text-align: left;
}
table #activate {
  color: green;
}
table #deactivate {
  color: red;
}
tr:nth-child(even) {
  box-shadow: 0px 0px 15px #00000012;
  border-radius: 20px;
}
td {
  padding: 25px 0;
}
th {
  padding-bottom: 40px;
}
.left {
  text-align: left;
}
.center {
  text-align: center;
}
button {
  cursor: pointer;
}
.white-bg {
  background: white;
}
.search {
  box-shadow: 0px 0px 7px #00000012;
  text-align: center;
  padding: 11px 35px;
  width: 237px;
}
.input {
  position: relative;
}
.input i {
  position: absolute;
  left: 8%;
  top: 50%;
  transform: translate(0, -50%);
  color: #00bbff;
  transition: all 0.3s;
}
.search:placeholder {
  padding-left: 30px;
}
.hidden {
  visibility: hidden;
}
.scroll {
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
}
.padding-40 {
  padding: 40px;
  box-sizing: border-box;
}
.unset {
  align-items: unset;
}
.fa-long-arrow-alt-right {
  display: none !important;
  color: #00bbff;
  font-size: 3rem;
  position: absolute;
  bottom: 0px;
  right: 20px;
  --animate-duration: 2s;
  z-index: 6;
  animation-iteration-count: infinite;
}
.fa-chevron-left {
  color: #00bbff;
  font-size: 1.5rem;
}
.active-users > div {
  overflow-y: auto;
  padding: 22px;
  height: 300px;
}
.active-users > div > div {
  justify-content: unset;
}
.active-users span {
  font: normal normal bold 1.125rem/22px Montserrat;
  color: #434343;
  white-space: pre;
}
.active-users h5 {
  width: 50% !important;
}
.active-users h6 {
  font: normal normal normal 1rem/19px Montserrat;
  color: #434343;
  padding: 24px 36px;
  width: 60%;
  margin: 0;
  margin-left: 30px;
}
.active-users .image {
  width: 44px;
  height: 44px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.active-users .image img {
  margin-right: -20px;
}
.active-users > .flex {
  justify-content: flex-start;
  margin-bottom: 40px;
  height: unset;
}
.active-users > .flex h5 {
  margin-left: 30px;
}
.active-users i {
  font-size: 28px;
  color: #00bbff;
  margin-right: 16px;
}
td {
  color: #434343;
}
.links {
  width: 315px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-right: 20px;
}
.links svg {
  margin: 0 7px 0 15px;
}
.links a {
  height: 40px;
  margin: 0;
  margin-bottom: 5px;
  box-shadow: 0px 0px 15px #00000012;
  border-radius: 20px;
  background: #ffffff;
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  font: normal normal normal 1.56rem/20px Montserrat;
  color: #434343;
}
.links .active {
  background: #00bbff;
  color: white;
  font-weight: bold;
}
.links .active tspan {
  fill: white;
}
.links a:hover {
  background: #00bbff;
  color: white;
  font-weight: bold;
  transition: 0.3s;
}
.links a:hover tspan {
  fill: white;
}
.register-monitoruesi {
  padding: 0 2% 0 11%;
}
.register-monitoruesi a {
  padding: 0 25px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.27);
  border-radius: 24px;
  margin-bottom: 15px;
}
.register-monitoruesi a h5 {
  color: white;
  font-weight: 500;
}
.register-monitoruesi a:hover {
  background: #4c9dbb;
  transition: 0.3s;
}
.register-monitoruesi svg {
  margin-right: 9px;
}
.upload-mobile {
  padding: 0 10px;
  text-decoration: none;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00bbff;
}
.invalid {
  color: red;
  font: normal normal normal 0.85rem/24px Montserrat;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}
.mobile-nav {
  align-items: center;
  justify-content: space-between;
  margin: 0;
  display: none;
}
.toggler {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background: #00bbff;
  border-radius: 5px;
  padding: 1.5px 4px;
}
.toggler span {
  width: 25px;
  height: 3px;
  background: white;
  margin-bottom: 2.5px;
  margin-top: 2.5px;
  border-radius: 20px;
  transition: 0.5s;
}
.nav-bar {
  background: white;
  box-shadow: 0px 0px 15px #00000024;
  border-radius: 43px;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-right: 25px;
  margin-top: 60px;
  padding: 7px 25px;
  width: 7%;
  box-sizing: border-box;
  z-index: 999;
}
.nav-bar a {
  width: 75px;
  height: 75px;
  margin-bottom: 33px;
  margin-top: 33px;
  text-decoration: none;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-bar a:hover {
  box-shadow: 0px 10px 30px #00000045;
  background: #00bbff;
  transition: all 0.3s;
}
.nav-bar a:hover ellipse {
  fill: #00bbff;
  transition: all 0.3s;
}
.nav-bar a:hover text {
  fill: #fff;
  font-weight: bolder;
  transition: all 0.3s;
}
.nav-bar a:hover path {
  fill: white;
  transition: all 0.3s;
}
.nav-bar a:hover .st0 {
  fill: white;
  transition: all 0.3s;
}
.nav-bar span {
  font-size: 1.5rem;
  font-weight: bold;
  color: #434343;
  display: none;
}
.nav-bar .active {
  box-shadow: 0px 10px 30px #00000045;
  border-radius: 12px;
  background: #00bbff;
}
.nav-bar .active svg path {
  fill: white;
}
.nav-bar .active svg text {
  fill: #fff;
  font-weight: bolder;
  transition: all 0.3s;
}
.nav-bar .active svg ellipse {
  fill: #00bbff;
  transition: all 0.3s;
}
.nav-bar .active svg .st0 {
  fill: #fff;
}
.nav-bar .plus {
  box-shadow: 0px 10px 30px #00000045;
  border-radius: 38px;
  background: #00bbff;
  height: 75px;
  width: 75px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-bar .plus:hover {
  background: #4c9dbb;
}
@media screen and (max-width: 1600px) {
  .nav-bar {
    margin-right: 15px;
    top: 233px;
  }
  .nav-bar a {
    margin-bottom: 22px;
    margin-top: 22px;
    width: 65px;
    height: 65px;
  }
  .nav-bar .plus {
    height: 58px;
    width: 58px;
  }

  #dashboard {
    width: 30px !important;
    height: 30px !important;
  }

  #add-credit-icon {
    width: 37px !important;
    height: 35px !important;
  }

  #statistics-icon {
    width: 32px !important;
    height: 29px !important;
  }

  #profile-icon {
    width: 30px !important;
    height: 33px !important;
  }

  #help-center-icon {
    width: 33px !important;
    height: 28px !important;
  }

  #Administrimi {
    width: 39px;
    height: 30px;
  }

  #kerkesa {
    width: 41px !important;
    height: 41px !important;
  }

  #njesit-akademike-icon {
    width: 38px !important;
  }

  #lista-e-ligjeruesve-icon {
    width: 38px !important;
  }

  #lista-e-studenteve-icon {
    width: 30px !important;
  }

  #subjects {
    width: 30px !important;
  }
}
@media screen and (max-width: 1024px) {
  .nav-bar {
    width: 8%;
  }
  .nav-bar .plus {
    height: 53px;
    width: 53px;
  }
  .nav-bar a {
    width: 55px;
    height: 55px;
  }
  .nav-bar #dashboard {
    width: 25px !important;
    height: 25px !important;
  }
  .nav-bar #textDetector {
    width: 32px !important;
  }
  .nav-bar #add-credit-icon {
    width: 32px !important;
    height: 30px !important;
  }
  .nav-bar #statistics-icon {
    width: 27px !important;
    height: 24px !important;
  }
  .nav-bar #profile-icon {
    width: 25px !important;
    height: 27px !important;
  }
  .nav-bar #help-center-icon {
    width: 28px !important;
    height: 25px !important;
  }
  .nav-bar #Administrimi {
    width: 33px;
    height: 26px;
  }
  .nav-bar #kerkesa {
    width: 36px !important;
    height: 36px !important;
  }
  .nav-bar #njesit-akademike-icon {
    width: 31px !important;
  }
  .nav-bar #lista-e-ligjeruesve-icon {
    width: 31px !important;
  }
  .nav-bar #lista-e-studenteve-icon {
    width: 25px !important;
  }
  .nav-bar #students {
    width: 31px !important;
  }
}
@media screen and (max-width: 768px) {
  .upload-mobile svg {
    height: 35px;
  }

  .mobile-nav {
    display: flex;
    margin-bottom: 15px;
  }

  .toggler {
    display: flex;
  }

  section {
    margin-right: 0;
    flex-direction: column;
    align-items: flex-end;
  }
  section > div {
    width: 100%;
  }
  section > main {
    width: 100%;
  }

  .nav-bar {
    margin: 0;
    position: absolute;
    transition: 0.4s;
    right: 0;
    width: 0 !important;
    background: rgba(255, 255, 255, 0.9);
    left: 2% !important;
    top: 155px !important;
    border-radius: 20px !important;
    padding: 23px 0;
    overflow: hidden;
    box-sizing: border-box;
  }
  .nav-bar a {
    transition: 0.4s;
    margin-bottom: 5px;
    margin-top: 5px;
    display: flex;
    justify-content: flex-start;
    height: 30px;
    font-family: Montserrat;
    box-shadow: 0px 5px 20px #00000045;
    align-items: center;
    background: white;
    width: 180px;
    padding: 10px;
    opacity: 0;
  }
  .nav-bar span {
    display: inline-block;
    transition: 0.4s;
    font-size: 1.3rem;
  }
  .nav-bar svg {
    margin: 0 20px;
  }
  .nav-bar .plus {
    height: 43px;
    width: 43px;
  }
  .nav-bar a:hover {
    transition: 0.3s;
  }
  .nav-bar a:hover span {
    color: white;
  }
  .nav-bar .active span {
    color: white;
  }
  .nav-bar .plus {
    height: 43px;
    width: 43px;
    display: none !important;
  }
  .nav-bar #dashboard {
    width: 20px !important;
    height: 20px !important;
  }
  .nav-bar #textDetector {
    width: 25px !important;
  }
  .nav-bar #add-credit-icon {
    width: 27px !important;
    height: 25px !important;
  }
  .nav-bar #statistics-icon {
    width: 22px !important;
    height: 19px !important;
  }
  .nav-bar #profile-icon {
    width: 20px !important;
    height: 22px !important;
  }
  .nav-bar #help-center-icon {
    width: 23px !important;
    height: 20px !important;
  }
  .nav-bar #kerkesa {
    width: 31px !important;
    height: 31px !important;
  }
  .nav-bar #Administrimi {
    width: 28px;
  }
}
@media screen and (max-width: 500px) {
  .invalid {
    font-size: 1rem;
  }
}
@media screen and (max-width: 1440px) {
  .nav-bar {
    top: 205px;
  }

  .register-monitoruesi svg {
    height: 45px !important;
  }
}
@media screen and (max-width: 768px) {
  .register-monitoruesi {
    width: 89%;
    position: absolute;
    top: 95px;
    padding: 0 2% 0 60px;
  }
  .register-monitoruesi a {
    right: 2%;
    margin-bottom: 0;
    padding: 0 18px;
  }
  .register-monitoruesi svg {
    height: 35px !important;
    margin-right: 5px;
  }
}
@media screen and (max-width: 500px) {
  .register-monitoruesi {
    padding: 0 2% 0 50px;
    top: 80px;
    width: 82%;
  }
  .register-monitoruesi h4 {
    font-size: 1.35rem;
  }
}
@media screen and (max-width: 1440px) {
  .number-of-words-submissions h5 {
    padding: 15px 36px 15px 27px;
  }
}
@media screen and (max-width: 768px) and (min-width: 500px) {
  .links > a:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 1600px) {
  .links a {
    height: 30px;
  }
}
@media screen and (max-width: 1440px) {
  .links {
    margin-right: 10px;
    width: 22%;
  }
  .links a {
    padding: 30px 10px;
  }
  .links svg {
    margin: 0 15px 0 20px;
  }
}
@media screen and (max-width: 1024px) {
  .nav-bar {
    top: 179px;
    border-radius: 22px;
    left: -6px;
  }

  .cdk-overlay-pane {
    width: 70% !important;
  }
}
@media screen and (max-width: 1023px) {
  .links a {
    height: 40px;
    width: 100%;
    padding: 20px 7px;
  }
}
@media screen and (max-width: 768px) {
  .cdk-overlay-pane {
    width: 90% !important;
  }

  .links {
    width: 100%;
    flex-direction: row !important;
  }
  .links svg {
    margin: 0;
    margin-right: 12px;
    width: 40px !important;
    height: 40px !important;
  }
  .links a {
    margin-right: 10px;
    padding: 15px 10px;
  }
}
@media screen and (max-width: 500px) {
  .links {
    width: 100%;
    flex-direction: column !important;
    justify-content: flex-start;
  }
  .links a {
    box-sizing: border-box;
    width: 100%;
    padding: 15px 10px;
  }
}
@media screen and (max-width: 2560px) {
  table td:nth-child(1) {
    width: 350px;
  }
}
@media screen and (max-width: 2000px) {
  table td:nth-child(1) {
    width: 250px;
  }
}
@media screen and (max-width: 1900px) {
  html {
    font-size: 85% !important;
  }

  .statistics .statistics-2 > div {
    padding: 20px 20px;
  }
}
@media screen and (max-width: 1600px) {
  html {
    font-size: 75% !important;
  }
}
@media screen and (max-width: 1440px) {
  header {
    margin-bottom: 20px;
  }

  html {
    font-size: 70% !important;
  }

  body > h4 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .dashboard-items .image {
    width: 65px;
    height: 65px;
  }
  .dashboard-items h3 {
    line-height: 20px;
  }

  .search {
    padding: 5px 20px;
    width: 200px;
  }

  .submit {
    margin-top: 15px;
    padding: 8px 40px;
  }

  table {
    margin-bottom: 30px;
    margin-top: 15px;
  }
  table th:nth-child(1) h5 {
    margin-left: 41px;
  }
  table td:nth-child(1) h5 {
    margin-left: 41px;
  }

  .red-border,
.green-border,
.orange-border,
.gray-border {
    padding: 7px 7px;
  }
  .red-border > span,
.green-border > span,
.orange-border > span,
.gray-border > span {
    padding: 13px 0 11px 0;
    margin-right: 8px;
    padding-right: 8px;
  }

  th {
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 1200px) {
  html {
    font-size: 65% !important;
  }

  .padding-40 {
    padding: 10px;
  }
}
@media screen and (max-width: 1100px) {
  html {
    font-size: 60% !important;
  }

  .red-border span,
.green-border span,
.orange-border span,
.gray-border span {
    padding: 14px 0 13px 0;
    padding-right: 8px;
  }

  .active-users h6 {
    padding: 16px 8px;
    margin-left: 15px;
    border-radius: 16px;
  }
  .active-users > .flex {
    margin-bottom: 0;
    padding: 10px;
  }
}
@media screen and (max-width: 1023px) {
  header .logo {
    width: 19%;
  }
  header svg {
    width: 28px;
    height: 30px;
    margin-left: 30px;
  }

  body > h4 {
    margin-left: 13%;
  }

  table {
    border-collapse: collapse;
  }
  table .left {
    margin-left: 20px;
  }

  .green-border,
.red-border,
.orange-border,
.gray-border {
    padding: 5px;
  }
}
@media screen and (max-width: 768px) {
  .w-87 {
    margin: 0;
    position: absolute;
    left: 70px;
    top: 115px;
  }

  header h5 {
    font-size: 1.1rem;
  }

  .fa-long-arrow-alt-right {
    display: inline-block !important;
  }

  .red-border span,
.gray-border span,
.orange-border span,
.green-border span {
    padding: 11px 0 12px 0;
    padding-right: 8px;
  }

  body > h4 {
    margin-left: 0;
    position: absolute;
    top: 105px;
    left: 70px;
  }

  table {
    width: 800px;
  }
  table td:nth-child(1) {
    width: 200px;
  }
  table th:nth-child(1) h5 {
    margin-left: 21px;
  }
  table td:nth-child(1) h5 {
    margin-left: 21px;
  }

  .active-users > div {
    padding: 10px;
  }
  .active-users h6 {
    padding: 15px 5px;
    margin-left: 6px;
  }
}
@media screen and (max-width: 767px) {
  header {
    margin-bottom: 20px;
  }
  header .logo {
    width: 22%;
  }

  section {
    margin-top: 0 !important;
  }

  main {
    margin-top: 0;
  }

  .search {
    width: 150px;
  }

  .input i {
    left: 10%;
  }

  table {
    width: 800px;
  }

  .active-users > div {
    height: 250px;
  }
}
@media screen and (max-width: 500px) {
  .nav-bar {
    top: 129px !important;
  }

  .w-87 {
    top: 95px;
    left: 51px;
    width: 82%;
  }

  .padding-40 {
    padding: 10px;
  }

  header {
    padding: 10px 0;
  }
  header .flex > div {
    width: 100%;
    justify-content: space-between;
  }
  header .flex > div > div {
    width: unset;
  }
  header svg {
    display: none;
  }
  header .logo {
    position: absolute;
    width: 27%;
    left: 35%;
  }

  html {
    font-size: 55% !important;
  }

  .search {
    padding: 3px 10px;
  }

  .red-border,
.orange-border,
.green-border,
.gray-border {
    padding: 4px;
  }
  .red-border span,
.orange-border span,
.green-border span,
.gray-border span {
    padding: 11px 0 10px 0;
    padding-right: 8px;
  }

  body > h4 {
    top: 85px;
  }
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  color: #434343;
}
mark {
  padding: 0 !important;
  color: transparent;
  cursor: pointer;
  background: none;
  font-family: unset !important;
}
.disable-btn {
  font-size: 1rem;
  line-height: 24px;
  color: white;
  background: #8fa8b1 !important;
  cursor: pointer;
  padding: 5px;
  border-radius: 10px;
}
.disable {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
.markQuote {
  color: white;
  font-weight: bold;
  position: absolute;
  font-size: 15px;
  left: -9px;
  height: 100%;
  top: -18px;
  border: 1px solid black;
  border-radius: 4px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
  align-items: center;
  display: flex;
  width: 16px;
  justify-content: center;
}
::selection {
  background: #0000ff38 !important;
}